// components/Navbar.js
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  InputAdornment,
  Popover,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import { CalendarToday, Notifications, Search } from "@mui/icons-material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NightsStayTwoToneIcon from "@mui/icons-material/NightsStayTwoTone";
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone";
import { useTheme } from "@mui/material/styles";
import CommunityDark from "../assets/Community B.png";
import CommunityLight from "../assets/Community.png";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import AppDropdown from "./apps-header/AppDropdown"; // Import your AppDropdown component
import { Icon } from "@iconify/react/dist/iconify.js";
import UserAvatar from "./userSection/UserAvatar";
import { Link } from "react-router-dom";
import NotificationsDropdown from "./Notifications";

const notifications = [
  { id: 1, user: "user1", message: "liked your post", avatarUrl: "url1" },
  {
    id: 2,
    user: "user2",
    message: "commented on your post",
    avatarUrl: "url2",
  },
  // Add more notifications here
];

function Navbar({ toggleDarkMode, darkMode }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "notifications-popover" : undefined;

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.mode === "dark" ? "#18191B" : "",
        height: "52px",
        boxShadow: "none",
        borderBottom: darkMode ? "0.5px solid #2b2b2b" : "0.5px solid #f2f2f2",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 15px",
          backgroundColor:
            theme.palette.mode === "dark" ? "#18191B" : "#FEFEFE",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "-1%" }}
        >
          <img
            src={darkMode ? CommunityDark : CommunityLight}
            height={isSmallScreen ? 35 : 45}
            width={isSmallScreen ? 105 : 145}
            alt="Community Logo"
          />
          {!isSmallScreen && (
            <TextField
              variant="outlined"
              placeholder="Search"
              sx={{
                mx: 2,
                width: 250,
                height: 35,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      type="submit"
                      color={darkMode ? "inherit" : "#111"}
                      sx={{ p: 0 }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>

        <Box
          sx={{
            marginTop: "-1%",
            display: isSmallScreen ? "none" : "flex",
            ml: -30,
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <Typography
                variant="h6"
                sx={{
                  margin: "0px",
                  color: "#008dd2",
                  fontSize: "14px",
                  fontWeight: "700 !important",
                }}
              >
                {time}
              </Typography>
              <p
                style={{
                  margin: "0px",
                  fontSize: "11px",
                  color: darkMode ? "inherit" : "#111",
                }}
              >
                {date}
              </p>
            </div>
            <div className="ms-1">
              <Link to="https://calendar.silocloud.io/">
                <IconButton>
                  <CalendarMonthOutlinedIcon fontSize="20px" />
                </IconButton>
              </Link>
            </div>
          </div>
        </Box>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "-1%",
          }}
        >
          <IconButton
            sx={{ padding: "0 15px" }}
            color={darkMode ? "inherit" : "#111"}
            onClick={handleFullscreenToggle}
          >
            {isFullscreen ? (
              <FullscreenExitRoundedIcon fontSize="13px" />
            ) : (
              <CropFreeRoundedIcon fontSize="13px" />
            )}
          </IconButton>
          {/* <IconButton
            sx={{ padding: "0 10px" }}
            color={darkMode ? "inherit" : "#111"}
            onClick={handleNotificationsClick}
            aria-describedby={id}
          >
            <Badge badgeContent={notifications.length} color="error">
              <Icon icon="clarity:notification-solid" />
            </Badge>
          </IconButton> */}
          <NotificationsDropdown />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List style={{ width: "300px" }}>
              {notifications.map((notification) => (
                <ListItem key={notification.id}>
                  <ListItemAvatar>
                    <Avatar src={notification.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.user}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {notification.message}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Popover>
          <AppDropdown /> {/* Replace the apps icon with AppDropdown */}
          <UserAvatar
            onToggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
            avatarUrl="https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png"
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, Typography, useMediaQuery } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useDispatch } from "react-redux";
import { setFilteredImage } from "../../store/slices/filteredImageSlice";
const filters = [
  { name: "Normal", style: "" },
  { name: "Clarendon", style: "contrast(1.4) saturate(1.5)" },
  { name: "Gingham", style: "contrast(1.1) brightness(1.1) sepia(0.04)" },
  { name: "Moon", style: "grayscale(1) contrast(1.1) brightness(1.1)" },
  {
    name: "Lark",
    style: "contrast(0.9) saturate(1.5) brightness(1.1) sepia(0.2)",
  },
  { name: "Reyes", style: "brightness(1.1) saturate(0.75) contrast(0.85)" },
  { name: "Juno", style: "contrast(1.15) saturate(1.8)" },
  { name: "Slumber", style: "brightness(0.9) saturate(0.85) sepia(0.35)" },
  { name: "Crema", style: "brightness(1.05) sepia(0.5)" },
  { name: "Ludwig", style: "contrast(1.05) brightness(0.9) saturate(1.25)" },
  { name: "Aden", style: "brightness(1.2) saturate(0.85) sepia(0.2)" },
  { name: "Perpetua", style: "brightness(1.1) saturate(1.3) contrast(0.9)" },
  { name: "Amaro", style: "brightness(1.1) contrast(0.9) sepia(0.2)" },
  { name: "Mayfair", style: "contrast(1.1) saturate(1.1) brightness(1.15)" },
  {
    name: "Rise",
    style: "brightness(1.15) contrast(0.9) saturate(0.9) sepia(0.2)",
  },
  {
    name: "Hudson",
    style: "brightness(0.9) contrast(1.2) saturate(1.1) sepia(0.15)",
  },
  { name: "Valencia", style: "sepia(0.15) contrast(1.08) brightness(1.08)" },
  { name: "X-Pro II", style: "contrast(1.25) saturate(1.25) brightness(0.9)" },
  { name: "Willow", style: "grayscale(0.5) contrast(0.95) brightness(0.95)" },
  { name: "Lo-Fi", style: "contrast(1.5) saturate(1.8) brightness(0.9)" },
  { name: "Inkwell", style: "grayscale(1) contrast(1.2) brightness(1.1)" },
  { name: "Hefe", style: "contrast(1.5) saturate(1.3) brightness(0.9)" },
  { name: "Nashville", style: "sepia(0.2) contrast(1.5) saturate(1.2)" },
  { name: "Toaster", style: "contrast(1.5) brightness(0.9) sepia(0.2)" },
  { name: "Walden", style: "brightness(1.5) hue-rotate(-10deg) saturate(1.5)" },
];
const PostFilters = ({ imageSrc, onImageProcessed }) => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0].style);
  const filtersRef = useRef(null);
  const canvasRef = useRef(null);

  const dispatch = useDispatch();

  const scrollFilters = (direction) => {
    if (filtersRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      filtersRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  const applyFilterAndDownload = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.filter = selectedFilter;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Get the data URL of the canvas
      const filteredImageURL = canvas.toDataURL("image/png");
      dispatch(setFilteredImage(filteredImageURL));

      // Pass to the next component
      // onImageProcessed(filteredImageURL);

      // Trigger download
      // const link = document.createElement("a");
      // link.href = filteredImageURL;
      // link.download = "filtered-image.png";
      // link.click();
    };
  };

  useEffect(() => {
    applyFilterAndDownload();
  }, [selectedFilter]);

  return (
    <div className="post-modal" style={{ padding: "0 20px" }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <div className="image-preview">
            <canvas ref={canvasRef} style={{ display: "none" }} />
            <img
              src={imageSrc}
              alt="Selected"
              className="post-image"
              style={{
                filter: selectedFilter,
                height: isMobile ? "300px" : "400px",
                maxWidth: isMobile ? "300px" : "490px",
                margin: "0 5px",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            className="filters-section"
            style={{
              width: isMobile ? "300px" : "500px",
            }}
          >
            <Button
              className="scroll-button left"
              onClick={() => scrollFilters("left")}
              startIcon={<KeyboardDoubleArrowLeftIcon />}
            />
            <div className="filters-list custom-scrollbar" ref={filtersRef}>
              {filters.map((filter) => (
                <Button
                  key={filter.name}
                  className={`filter-button ${
                    selectedFilter === filter.style ? "active" : ""
                  }`}
                  style={{
                    filter: filter.style,
                    borderRadius: 8,
                    padding: "5px",
                    minWidth: isMobile ? "60px" : "80px",
                    marginRight: isMobile ? "8px" : "10px",
                    boxShadow:
                      selectedFilter === filter.style
                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                        : "none",
                  }}
                  onClick={() => setSelectedFilter(filter.style)}
                >
                  <img
                    src={imageSrc}
                    alt={filter.name}
                    className="filter-preview"
                    style={{
                      width: isMobile ? "40px" : "50px",
                      height: "auto",
                      borderRadius: 4,
                    }}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight:
                        selectedFilter === filter.style ? "bold" : "normal",
                      fontSize: "11px",
                      textTransform: "capitalize",
                    }}
                  >
                    {filter.name}
                  </Typography>
                </Button>
              ))}
            </div>
            <Button
              className="scroll-button right"
              onClick={() => scrollFilters("right")}
              endIcon={<KeyboardDoubleArrowRightIcon />}
            />
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <Button variant="contained" onClick={applyFilterAndDownload}>
            Apply Filter and Download
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default PostFilters;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Post from "./Post";
import { getRequest } from "../api-service";
import { useQuery } from "@tanstack/react-query";
import { handleGetPosts } from "../http/getApiCalls";

function Feed() {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark"; // Check if the theme is dark
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is small

  const [activeCategory, setActiveCategory] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null); // State to control menu anchor element
  const [loading, setLoading] = useState(true);
  const [post, setPosts] = useState([]);

  // const handleGetPosts = () => {
  //   setLoading(true);
  //   getRequest("/community-post/get-all-user-posts").then((res) => {
  //     console.log("posts data:", res.data.postData);
  //     setPosts(res.data.postData);
  //     setLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   handleGetPosts(); // Fetch posts on component mount
  // }, []);

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData"],
    queryFn: async () => handleGetPosts(),
    gcTime: 0,
    staleTime: Infinity,
  });

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ padding: isSmallScreen ? "0px" : "0 120px" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
        {/* <Typography className="home-section-title">Recent Post</Typography> */}

        {isSmallScreen ? (
          <>
            <Box
              sx={{
                ml: "auto",
                border: "1px solid grey",
                borderRadius: 2,
                height: "fit-content",
              }}
            >
              <IconButton
                onClick={handleMenuOpen}
                size="small"
                sx={{ ml: "auto" }}
              >
                <span style={{ fontSize: 14 }}> {activeCategory}</span>
                <MoreVertIcon />
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {["All", "Following", "Latest", "Popular"].map((category) => (
                <MenuItem
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                  selected={activeCategory === category}
                  sx={{
                    backgroundColor:
                      activeCategory === category
                        ? (theme) => theme.palette.action.selected
                        : "inherit",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        activeCategory === category
                          ? "inherit"
                          : darkMode
                          ? "#787777"
                          : "#aaadab",
                      fontWeight: activeCategory === category ? 900 : "normal",
                    }}
                  >
                    {category}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box
            className="home-section-title"
            sx={{ display: "flex", justifyContent: "end", flexGrow: 1, gap: 3 }}
          >
            {["All", "Following", "Latest", "Popular"].map((category) => (
              <Typography
                key={category}
                onClick={() => handleCategoryChange(category)}
                sx={{
                  color:
                    activeCategory === category
                      ? "inherit"
                      : darkMode
                      ? "#787777"
                      : "#aaadab",
                  fontWeight: activeCategory === category ? 900 : "normal",
                  mx: 1,
                  cursor: "pointer",
                }}
              >
                {category}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      <Grid container spacing={2}>
        {postsData?.map((post) => (
          <Grid item xs={12} key={post.id}>
            <Post
              post={post}
              loading1={loading}
              postData={postsData}
              postFinalRefetch={postFinalRefetch}
              darkMode={darkMode}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Feed;

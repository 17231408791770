import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Dialog,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlined from "@mui/icons-material/PauseOutlined";
import { StopCircleOutlined } from "@mui/icons-material";
import CommentsModal from "../comments/CommentsModal";

const ProfileSectionPosts = ({ posts }) => {
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRefs = useRef({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Pause all videos except the selected one
    Object.values(videoRefs.current).forEach((video) => {
      if (video !== videoRefs.current[selectedPost?.id]) {
        video?.pause();
      }
    });
  }, [selectedPost]);

  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
  };

  const toggleMute = () => {
    if (videoRefs.current[selectedPost?.id]) {
      videoRefs.current[selectedPost?.id].muted = !muted;
      setMuted(!muted);
    }
  };

  const handlePlayPause = () => {
    if (videoRefs.current[selectedPost?.id]) {
      if (videoRefs.current[selectedPost?.id].paused) {
        videoRefs.current[selectedPost?.id].play();
        setIsPlaying(true);
      } else {
        videoRefs.current[selectedPost?.id].pause();
        setIsPlaying(false);
      }
    }
  };

  const handleStop = () => {
    if (videoRefs.current[selectedPost?.id]) {
      videoRefs.current[selectedPost?.id].pause();
      videoRefs.current[selectedPost?.id].currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {loading ? (
        <>
          <Grid container spacing={0.4} rowSpacing={2.5} textAlign={"start"}>
            {Array.from(new Array(9))?.map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={250}
                  sx={{ mr: 1, mt: -2 }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Grid container spacing={0.4} rowSpacing={-2} textAlign={"start"}>
          {posts?.map((post) => {
            const media = post.media_details[0]; // Assuming media array contains only one item
            const mediaPath = media.media_urls;
            const mediaType = media.media_type;

            const isImage = mediaType === "image";
            const isVideo = mediaType === "video";

            return (
              <Grid item xs={12} sm={6} md={4} key={post.id} mt={-0.29}>
                {isImage && (
                  <Box
                    component="img"
                    src={mediaPath}
                    alt={post.caption}
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      height: 250,
                      objectFit: "cover",
                      transition: "0.3s ease-in-out",
                      "&:hover": {
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => handlePostClick(post)}
                  />
                )}
                {isVideo && (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: 250,
                      cursor: "pointer",
                      overflow: "hidden",
                      "&:hover video": {
                        opacity: 0.8,
                      },
                      "&:hover .controls": {
                        opacity: 1,
                      },
                    }}
                    onClick={() => handlePostClick(post)}
                  >
                    <video
                      ref={(el) => (videoRefs.current[post.id] = el)}
                      src={mediaPath}
                      loop
                      autoPlay
                      muted={muted}
                      playsInline
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "0.3s ease-in-out",
                      }}
                    />
                    {/* Optional controls here */}
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        {selectedPost && (
          <>
            {/* <CommentsModal
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              imageUrl={JSON.parse(selectedPost.media)[0].path}
              caption={selectedPost.caption}
            /> */}
            <CommentsModal
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              postData={selectedPost}
              imageUrl={selectedPost?.media_details[0]?.media_urls}
              caption={selectedPost.caption}
              // postFinalRefetch={postFinalRefetch}
            />
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default ProfileSectionPosts;

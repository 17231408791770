import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Paper,
  Box,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import StoryModal from "./stories/StoryModal"; // Import the StoryModal component
import { Icon } from "@iconify/react/dist/iconify.js";
import AddStoryModal from "./stories/AddStoryModal ";
import { getRequest } from "../api-service";
import storyBG from "../assets/images/storyBG.jpg";
import storyBG1 from "../assets/images/storyBG1.jpg.png";
import { useCookies } from "react-cookie";
import { handleGetStories } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";

function Stories() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [addStoryModalOpen, setAddStoryModalOpen] = useState(false);
  const [stories1, setStories1] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(null);
  const storiesRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const scrollLeft = () => {
    storiesRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const scrollRight = () => {
    storiesRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  const handleScroll = () => {
    const scrollLeftPos = storiesRef.current.scrollLeft;
    const maxScrollLeft =
      storiesRef.current.scrollWidth - storiesRef.current.clientWidth;

    // Update button visibility based on the scroll position
    setShowLeftButton(scrollLeftPos > 0);
    setShowRightButton(scrollLeftPos < maxScrollLeft);
  };

  useEffect(() => {
    const refCurrent = storiesRef.current;
    refCurrent.addEventListener("scroll", handleScroll);

    // Initial check to set correct button visibility
    handleScroll();

    return () => {
      refCurrent.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const stories = [
    {
      username: "Mili",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU2L5zZw6yNacp-7bBZkGTcrU-m6Gxezy10thku17TOu_L08d8Sh6cfJVpGqlpvhYJ_wQ&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerMeltdowns.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_one.jpeg  ",
    },
    {
      username: "Laura",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmUxolbX0lnjKcbXn9eMZHV9Vr2iQgu2vQwLgndljvud4lo7FB9KqanamPmCmci0GcU8I&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/BigBuckBunny.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_two.jpeg",
    },
    {
      username: "Garry",
      avatar:
        "https://img.freepik.com/premium-photo/graphic-designer-digital-avatar-generative-ai_934475-9292.jpg",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerJoyrides.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_three.jpg",
    },
    {
      username: "Morris",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqC3UgeCG_7vex4I9DAFQB4WmTz2wOWdTDxncF47Z1Y_lr3LJdM5gixPZRMjkxAOq9bp0&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerMeltdowns.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_four.jpg",
    },
    {
      username: "Menon",
      avatar:
        "https://img.freepik.com/premium-photo/graphic-designer-digital-avatar-generative-ai_934475-9193.jpg",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/BigBuckBunny.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_five.jpg",
    },
    {
      username: "Mili",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU2L5zZw6yNacp-7bBZkGTcrU-m6Gxezy10thku17TOu_L08d8Sh6cfJVpGqlpvhYJ_wQ&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerJoyrides.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_six.jpeg",
    },
    {
      username: "Laura",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmUxolbX0lnjKcbXn9eMZHV9Vr2iQgu2vQwLgndljvud4lo7FB9KqanamPmCmci0GcU8I&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerMeltdowns.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_seven.jpeg",
    },
    {
      username: "Harry",
      avatar: "url2",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/BigBuckBunny.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_eight.jpeg",
    },
    {
      username: "Mili",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU2L5zZw6yNacp-7bBZkGTcrU-m6Gxezy10thku17TOu_L08d8Sh6cfJVpGqlpvhYJ_wQ&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerJoyrides.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_one.jpeg",
    },
    {
      username: "Laura",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmUxolbX0lnjKcbXn9eMZHV9Vr2iQgu2vQwLgndljvud4lo7FB9KqanamPmCmci0GcU8I&usqp=CAU",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerJoyrides.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_two.jpeg",
    },
    {
      username: "Harry",
      avatar: "url2",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/ForBiggerMeltdowns.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_three.jpg",
    },
    {
      username: "Harry",
      avatar: "url2",
      storyVdo:
        "https://api.silocloud.io/assets/images/community/BigBuckBunny.mp4",
      thumbnail:
        "https://api.silocloud.io/assets/images/community/story_four.jpg",
    },
    // Add more stories here
  ];

  const {
    data: storyData,
    isLoading: storyDataIsLoading,
    isRefetching: storyDataIsRefetch,
    refetch: storyDataRefetch,
  } = useQuery({
    queryKey: ["getStoryData"],
    queryFn: async () => handleGetStories(),
    gcTime: 0,
    staleTime: Infinity,
  });

  const handleOpenModal = (index) => {
    setCurrentStoryIndex(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentStoryIndex(null);
  };

  const handleAddStory = () => {
    setAddStoryModalOpen(true);
  };

  const handleCloseAddStoryModal = () => {
    setAddStoryModalOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {/* <Paper> */}
      {showLeftButton && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            color: "#fff",
            padding: "3px",
          }}
        >
          <Icon icon="mdi:chevron-left" fontSize={20} />
        </IconButton>
      )}
      <Box
        ref={storiesRef}
        display="flex"
        className="custom-scrollbar"
        elevation={2}
        style={{
          padding: "10px 0px",
          marginBottom: 20,
          overflowX: "auto",
          whiteSpace: "nowrap",
          paddingRight: "10px",
        }}
      >
        {/* Add Story Box */}
        <div style={{ display: "block" }} ref={storiesRef}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "3px",
              ml: 1,
              borderRadius: "10px",
              backgroundColor:
                theme.palette.mode === "dark" ? "#474646" : "#f0eded",
              cursor: "pointer",
              minHeight: "170px",
              maxHeight: "190px",
              minWidth: "110px",
              maxWidth: "110px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 0 6px rgba(0, 0, 0, 0.3)"
                  : "0 0 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={handleAddStory}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "125px", // 80% of the box height
                width: "100%",
                position: "relative",
              }}
            >
              <Avatar
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-12%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.error.main,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                  border: `3px solid ${
                    theme.palette.mode === "dark" ? "#474646" : " #f0eded"
                  }`, // White border around the icon
                }}
              >
                <Icon
                  icon="mingcute:add-fill"
                  height={20}
                  style={{ color: "#fff" }}
                />
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", textAlign: "center", mt: 1 }}
            >
              Add Story
            </Typography>
          </Box>
        </div>

        {Array.isArray(storyData?.data) &&
          storyData.data.map((story, index) => (
            <div style={{ display: "block" }} ref={storiesRef}>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  padding: "3px",
                  ml: 1,
                  borderRadius: "5%",
                  backgroundImage: `url(${story.stories[0].mediaUrls})`,
                  backgroundSize: "cover", // Ensure the background covers the entire box
                  backgroundPosition: "center", // Center the image
                  cursor: "pointer",
                  minHeight: "170px",
                  minWidth: "110px",
                  maxWidth: "110px",
                  overflow: "hidden",
                  position: "relative", // Needed to position the overlay
                }}
                ref={storiesRef}
                onClick={() => handleOpenModal(index)}
              >
                <Box
                  sx={{
                    position: "absolute", // Overlay to darken the background
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.4)", // Black with 50% opacity
                    borderRadius: "5%", // Match the parent box's borderRadius
                    zIndex: 1, // Ensure the overlay is above the background image but below content
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    mt: 1,
                    ml: 1,
                    cursor: "pointer",
                    position: "relative", // Ensure content is above the overlay
                    zIndex: 2,
                  }}
                  onClick={() => handleOpenModal(index)}
                >
                  <span
                    style={{
                      backgroundImage: `url(${storyBG1})`,
                      backgroundSize: "cover", // Ensure the background covers the entire box
                      backgroundPosition: "center", // Center the image
                      padding: "3.2px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      height: 34,
                      width: 34,
                      marginTop: "auto",
                    }}
                  >
                    <Avatar
                      src={`https://api.silocloud.io/get-file-content/${story?.profile_image_path}`}
                      alt={story.username}
                      sx={{
                        height: 28,
                        width: 28,
                        borderRadius: "50%",
                        textTransform: "capitalize",
                      }}
                    />
                  </span>
                  <Typography
                    sx={{
                      mt: "auto",
                      mb: 0.5,
                      ml: 1,
                      fontWeight: "500 !important",
                      color: "#fff", // White color for better visibility on dark background
                      textTransform: "capitalize",
                    }}
                    fontSize={14}
                  >
                    {story.username.length > 5
                      ? story.username.substring(0, 5) + "..."
                      : story.username}
                  </Typography>
                </Box>
              </Box>
            </div>
          ))}
      </Box>
      {showRightButton && (
        <IconButton
          onClick={scrollRight}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            color: "#fff",
            padding: "3px",
          }}
        >
          <Icon icon="mdi:chevron-right" fontSize={20} />
        </IconButton>
      )}
      {/* </Paper> */}
      {openModal && (
        <StoryModal
          open={openModal}
          handleClose={handleCloseModal}
          storiesData={storyData?.data}
          currentIndex={currentStoryIndex}
        />
      )}

      {addStoryModalOpen && (
        <AddStoryModal
          open={addStoryModalOpen}
          handleClose={handleCloseAddStoryModal}
        />
      )}
    </div>
  );
}

export default Stories;

import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";

const MAPBOX_API_KEY =
  "pk.eyJ1IjoicHJha2FzaG5pbiIsImEiOiJjbHk4aHp6MTgwajN1MmtzYjlnd3RkZzJlIn0.c0MhCeRKmQCZ6zIYCypLaw";

export default function LocationAutocomplete({ value, onChange }) {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchSuggestions = async () => {
      if (inputValue) {
        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              inputValue
            )}.json?access_token=${MAPBOX_API_KEY}`
          );
          const data = await response.json();
          const suggestions = data.features.map((feature) => ({
            place_name: feature.place_name,
            main_text: feature.text,
            secondary_text: feature.place_name,
          }));
          setOptions(suggestions);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setOptions([]);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions();
    }, 300); // Debounce input by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  return (
    <Autocomplete
      id="mapbox-autocomplete"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.place_name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          maxRows={2}
          //   label="Add a location"
          placeholder="Add a location"
          fullWidth
          sx={{
            backgroundColor: "transparent", // Instagram-like background
            "& .MuiInputBase-root": {
              backgroundColor: "transparent", // Instagram-like background
              paddingTop: "12px", // Adjust padding for multiline TextField
              paddingBottom: "12px",
              borderBottom: "1px solid #dbdbdb", // Instagram-like border
              height: "auto", // Allows the TextField to be flexible with content but limited by maxHeight
              //   maxHeight: "50px", // Adjust this value to set the maximum height
              resize: "none", // Disable resizing to maintain fixed height
              "&.Mui-focused": {
                backgroundColor: "transparent",
                "& .MuiInputBase-input": {
                  borderBottomColor: "#dbdbdb", // Ensure the border color remains consistent
                },
              },
              "&:hover": {
                backgroundColor: "transparent", // Remove background color on hover
              },
            },
            "& .MuiInput-underline:before": {
              display: "none", // Remove underline before focus
            },
            "& .MuiInput-underline:after": {
              display: "none", // Remove underline after focus
            },
            "& .MuiInput-underline:hover:before": {
              display: "none", // Remove underline on hover
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const parts = parse(
          option.main_text,
          [[0, option.main_text.length]] // Highlight the entire main text
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

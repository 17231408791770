import React from 'react';
import { useParams } from 'react-router-dom';
import Profile from '../components/Profile';

function ProfilePage() {
  const { username } = useParams();
  console.log('username',username)

  return (
    <div>
      <Profile username={username} />
    </div>
  );
}

export default ProfilePage;

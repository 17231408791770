import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = " https://api.silocloud.io/api/v1"; // Replace with your API base URL
const CONNECTION_URL = "https://api.silocloud.io/api/v1/connection";
const token = Cookies.get("authToken");

// Example of a GET request
export const getRequest = async (route) => {
  try {
    let config = {
      method: "get",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };
    // console.log("token", token);

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Example of a POST request
export const postRequest = async (route, data) => {
  try {
    let config = {
      method: "post",
      url: `${BASE_URL}${route}`,
      headers: {
        authToken: token,
      },
      //   data: JSON.stringify(data),
      data: data,
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

// Example of a PUT request
export const putRequest = async (route, data) => {
  try {
    let config = {
      method: "put",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
      data: JSON.stringify(data),
    };

    if (localStorage.getItem("token")) {
      console.log("token", token);
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error putting data:", error);
    throw error;
  }
};

// Example of a DELETE request
export const deleteRequest = async (route) => {
  try {
    let config = {
      method: "delete",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

// // api-service.js

// export const fetchCoins = async (payload) => {
//   const { data } = await axios.post('https://api.silocloud.io/recently-added', payload);
//   return data;
// };

// export default api;

export const postRequestGetUserProfile = async (route, data) => {
  try {
    let config = {
      method: "post",
      url: `https://api.silocloud.io/api/v1/account/${route}`,
      headers: {
        authToken: token,
      },
      //   data: JSON.stringify(data),
      data: data,
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

export const fetchCloudSearchApi = async (route) => {
  let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: token,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while AppDropdown.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
  return await response.json();
};

// Example of a POST request
export const postRequestFollow = async (route, data) => {
  try {
    let config = {
      method: "post",
      url: `${CONNECTION_URL}${route}`,
      headers: {
        authToken: token,
      },
      //   data: JSON.stringify(data),
      data: data,
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

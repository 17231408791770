import React from "react";
import { Grid } from "@mui/material";
import Post from "../components/Post";
import { handleGetPosts } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";

function Explore() {
  const posts = [
    { id: 1, username: "user1", imageUrl: "url1", caption: "caption1" },
    { id: 2, username: "user2", imageUrl: "url2", caption: "caption2" },
    // Add more posts here
  ];

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData"],
    queryFn: async () => handleGetPosts(),
    gcTime: 0,
    staleTime: Infinity,
  });
  console.log("postData explore", postsData);

  return (
    <Grid container spacing={2} mt={1}>
      {postsData?.map((post) => (
        <Grid item xs={12} sm={6} md={4} key={post.id}>
          <Post
            post={post}
            postData={postsData}
            postFinalRefetch={postFinalRefetch}
            // darkMode={darkMode}
          />{" "}
        </Grid>
      ))}
    </Grid>
  );
}

export default Explore;

import {
  postRequest,
  postRequestFollow,
  postRequestGetUserProfile,
} from "../api-service";

export const handlePostFinal = async (formData) => {
  //   setLoading(true);

  try {
    const res = await postRequest("/community-post/upload-post", formData);

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
    // setLoading(false);
  }
};

export const handlePostLike = async (formData) => {
  //   setLoading(true);

  try {
    // const res = await postRequest("/community-post/togglelike-post", formData);
    const res = await postRequest(
      "/community-post/like-dislike-post",
      formData
    );

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
    // setLoading(false);
  }
};

export const handlePostComment = async (formData) => {
  //   setLoading(true);

  try {
    const res = await postRequest(
      "/community-post/community-post-comments/get-comments",
      formData
    );

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
    // setLoading(false);
  }
};

//api for comment reply
export const handleReplyToComment = async (formData) => {
  //   setLoading(true);

  try {
    const res = await postRequest(
      "/community-post/community-post-comments/comment-reply",
      formData
    );

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
    // setLoading(false);
  }
};

//api for add story
export const handleAddStory = async (formData) => {
  //   setLoading(true);

  try {
    const res = await postRequest("/community-story/upload-story", formData);

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
    // setLoading(false);
  }
};

//api for save story as draft
export const handleDraftStory = async (formData) => {
  try {
    const res = await postRequest(
      "/community-story/community-draft-story/savedraft-story",
      formData
    );

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

//api for report a post
export const handleReportPost = async (formData) => {
  try {
    const res = await postRequest("/community-post/report-post", formData);

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

//api for get user profile
export const handleGetUserProfile = async () => {
  try {
    const res = await postRequestGetUserProfile("get-profile");

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

//api for getpost data
export const handleGetPostData = async (formData) => {
  try {
    const res = await postRequest(`/community-post/get-post-by-link`, formData);

    if (res.status === true) {
      return { status: true, message: res.message };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

//api for get silo users data
export const handleGetSiloUsers = async (formData) => {
  try {
    const res = await postRequest(`/wallet/users`, formData);

    if (res.status === true) {
      return { status: true, message: res.data };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

//api for get silo users data
export const handleFollowUser = async (formData) => {
  try {
    const res = await postRequestFollow(`/follow`, formData);

    if (res.status === true) {
      return { status: true, message: res.data };
    } else {
      throw new Error(res.errors[0]);
    }
  } catch (error) {
    console.error("Error:", error);

    // Check for network-related errors
    if (!error.response) {
      // Network error (e.g., no internet connection)
      throw new Error("Network error: Please check your internet connection.");
    } else {
      // Other errors (e.g., server responded with an error)
      throw new Error(error.message);
    }
  } finally {
  }
};

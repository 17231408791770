import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { keyframes } from "@emotion/react";

// Keyframes for smooth appearance
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const EmbedModal = ({ open, onClose, post }) => {
  const [embedCode] = useState(
    `<iframe src="https://example.com/embed/${post.id}" width="600" height="400" frameborder="0" allowfullscreen></iframe>`
  );

  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      toast.success("Embed code copied to your clipboard!", {
        position: "bottom-right",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="embed-modal-title"
      aria-describedby="embed-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: 500,
          bgcolor: "#ffffff",
          borderRadius: 10,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          p: 3,
          animation: `${fadeIn} 0.3s ease-out`,
          outline: "none",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ mb: 2 }}>
          Embed Code
        </Typography>
        <TextField
          multiline
          fullWidth
          minRows={4}
          value={embedCode}
          variant="outlined"
          InputProps={{ readOnly: true }}
          sx={{ mb: 2, borderRadius: 2, bgcolor: "#f7f7f7" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopyEmbedCode}
          sx={{
            borderRadius: 20,
            padding: "10px 20px",
            bgcolor: "#0095f6",
            color: "#ffffff",
            "&:hover": {
              bgcolor: "#007bb5",
            },
          }}
        >
          Copy Embed Code
        </Button>
      </Box>
    </Modal>
  );
};

export default EmbedModal;

import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Explore from "../pages/Explore";
import ProfilePage from "../pages/ProfilePage";
import NotFoundPage from "../pages/NotFoundPage";
import Cookies from "js-cookie";
import { postRequestGetUserProfile } from "../api-service";
import { updateUserStatus } from "../firebase/firebase-service";
import { Button } from "@mui/material";
import PostPage from "../components/post/PostPage";
import Chat from "../components/chats/Chat";
import OtherUserProfile from "../components/OtherUserProfile";

const AppRouter = () => {
  const location = useLocation();
  const authToken = Cookies.get("authToken");
  const [profile, setProfile] = useState(null); // State for profile

  // Fetch user profile and set status to active when browser opens
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (authToken) {
          const res = await postRequestGetUserProfile("get-profile", {
            authToken,
          });
          const userProfile = res.data.profile;
          setProfile(userProfile); // Set profile state
          localStorage.setItem("loggedIn_user_id", userProfile.user_id);

          if (userProfile?.user_id) {
            // Set user as active on browser open
            updateUserStatus(userProfile.user_id, true);
          }

          // console.log("Profile fetched:", userProfile);
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchUserProfile();
  }, [authToken]); // Re-fetch profile if token changes

  // Handle browser/tab close and set status to inactive
  useEffect(() => {
    const handleBrowserClose = () => {
      if (profile?.user_id) {
        updateUserStatus(profile.user_id, false); // Set user as inactive on browser close
      }
    };

    window.addEventListener("beforeunload", handleBrowserClose);

    return () => {
      window.removeEventListener("beforeunload", handleBrowserClose);
    };
  }, [profile]);

  // Handle token absence (logout redirect)
  useEffect(() => {
    if (!authToken) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours expiry for the current URL cookie
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });

      window.location = process.env.REACT_APP_ACCOUNT_URL;
    }
    try {
      if (!authToken) {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("currentURL", window.location.href, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
      }
    } catch (error) {
      console.error("Error checking KYC status:", error);
    }
  }, [authToken]);

  // Handle logout
  const handleLogout = () => {
    if (profile?.user_id) {
      updateUserStatus(profile.user_id, false); // Set user as inactive on logout
    }
    // Logout logic (e.g., remove cookies, redirect)
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/chats" element={<Chat />} />

        <Route path="/community_post/:postId" element={<PostPage />} />
        <Route path="/other/profile/:userID" element={<OtherUserProfile />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;

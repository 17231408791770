import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { fetchCloudSearchApi, getRequest } from "../../api-service";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import "../../css/main.css";

const AppDropdown = () => {
  const theme = useTheme();
  const isNightMode = theme.palette.mode === "dark";
  const appNameColor = isNightMode ? "#fff" : theme.palette.text.primary;

  const [isApps, setIsApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const authToken = Cookies.get("authToken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOldClick = (href, event) => {
    event.preventDefault();
    toast.info(
      <div>
        Very soon transforming to Silo 5.0,
        <br />
        in the meantime enjoy Silo 4.2
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
    setTimeout(() => {
      window.location.href = href;
    }, 2000);
  };

  const handleAppClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.href, event);
    } else {
      handleClose();
    }
  };

  const fetchApps = async () => {
    try {
      const result = await getRequest("/apps/get-apps");
      setIsApps([
        result?.data?.core_apps || [],
        result?.data?.social_apps || [],
        result?.data?.productivity_apps || [],
        result?.data?.exchange_apps || [],
      ]);
    } catch (error) {
      console.error("Error fetching apps:", error);
      toast.error("Failed to load apps");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchApps();
    }
  }, [open]);

  return (
    <>
      {/* <Tooltip title="Apps" arrow> */}
      <IconButton onClick={handleClick}>
        <Icon icon="ion:apps" fontSize={"20px"} />
      </IconButton>
      {/* </Tooltip> */}
      <Menu
        className="custom-scrollbar-chat"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: "305px",
            height: "305px",
            width: "320px",
            boxShadow: "0 3px 12px 1px #82252626",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#767474",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#c5c1c1 ",
            },
            overflowY: "auto", // Ensures scrollbar appears when necessary
          },
        }}
      >
        {isApps.map((main_app, main_index) => (
          <div key={main_index}>
            <Typography
              // variant="h6"
              sx={{
                padding: "10px 16px",
                color: "text.secondary",
                fontSize: "17px",
              }}
            >
              {main_app?.label}
            </Typography>
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="custom-scrollbar"
            >
              {loading
                ? Array.from(new Array(9)).map((_, index) => (
                    <div
                      key={index}
                      style={{ width: "33.33%", padding: "8px" }}
                    >
                      <Skeleton
                        variant="rectangular"
                        height={70}
                        animation="wave"
                      />
                    </div>
                  ))
                : main_app?.data.map((app, index) => (
                    <div
                      className="custom-scrollbar-chat"
                      key={index}
                      style={{ width: "33.33%", padding: "8px" }}
                    >
                      <Link
                        className="link-tag-style "
                        style={{ color: "#ffff" }}
                        to={
                          app?.name === "Talk"
                            ? `https://silotalk.com/token-login/${authToken}`
                            : app?.project_link
                        }
                      >
                        <MenuItem
                          onClick={(event) => handleAppClick(app, event)}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            "&:hover": {
                              backgroundColor: theme.palette.action.hover.main,
                              borderRadius: 1,
                            },
                          }}
                        >
                          <span
                            style={{
                              height: 36,
                              width: 36,
                              marginBottom: 2,
                              padding: "2px",
                            }}
                          >
                            <img
                              src={app?.image_link}
                              alt={app?.name}
                              style={{
                                height: "inherit",
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </span>
                          <Typography
                            variant="body2"
                            style={{ color: appNameColor }}
                          >
                            <span
                              className="list-apps-title"
                              dangerouslySetInnerHTML={{ __html: app?.name }}
                            />
                          </Typography>
                        </MenuItem>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
        ))}
      </Menu>
    </>
  );
};

export default AppDropdown;

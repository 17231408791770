import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { setFilteredImage } from "../../store/slices/filteredImageSlice";
import { FFmpeg } from "@ffmpeg/ffmpeg";

const VideoTrim = ({ videoSrc }) => {
  const [croppedVideoURL, setCroppedVideoURL] = useState(null);
  //   const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");

  // Initialize FFmpeg
  const ffmpeg = new FFmpeg({ log: true });

  //   useEffect(() => {
  //     const loadFFmpeg = async () => {
  //       if (!ffmpeg.isLoaded()) {
  //         await ffmpeg.load();
  //       }
  //       setIsLoading(false);
  //     };

  //     loadFFmpeg();
  //   }, [ffmpeg]);

  const trimVideo = async () => {
    if (videoSrc) {
      //   setIsLoading(true); // Set loading state before starting processing
      try {
        // Convert video source to a Blob and fetch it
        const response = await fetch(videoSrc);
        const videoBlob = await response.blob();
        const videoArrayBuffer = await videoBlob.arrayBuffer();
        ffmpeg.FS("writeFile", "input.mp4", new Uint8Array(videoArrayBuffer));

        // Define trimming parameters (e.g., first 30 seconds)
        const startTime = "00:00:00";
        const endTime = "00:00:30";

        // Run FFmpeg command to trim the video
        await ffmpeg.run(
          "-i",
          "input.mp4",
          "-ss",
          startTime,
          "-to",
          endTime,
          "-c",
          "copy",
          "output.mp4"
        );

        // Read the result
        const data = ffmpeg.FS("readFile", "output.mp4");
        const blob = new Blob([data.buffer], { type: "video/mp4" });
        const url = URL.createObjectURL(blob);

        setCroppedVideoURL(url);
        dispatch(setFilteredImage(url)); // Pass the cropped video URL to Redux
      } catch (error) {
        console.error("Error trimming video:", error);
      } finally {
        // setIsLoading(false); // Update loading state after processing
      }
    }
  };

  useEffect(() => {
    trimVideo();
  }, [videoSrc]);

  return (
    <div className="post-modal" style={{ padding: "0 20px" }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          {/* {isLoading ? (
            <CircularProgress />
          ) : ( */}
          <div className="video-preview">
            <video
              src={croppedVideoURL || videoSrc}
              controls
              style={{
                height: isMobile ? "300px" : "400px",
                maxWidth: isMobile ? "300px" : "490px",
                margin: "0 5px",
              }}
            />
          </div>
          {/* )} */}
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoTrim;

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Slider,
  useMediaQuery,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
  useTheme,
  Grid,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Icon } from "@iconify/react/dist/iconify.js";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import Cropper from "react-easy-crop";
import PostFilers from "./post/PostFilers";
import { getCroppedImg } from "./post/GetCroppedImage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import PostFinalStepForm from "./post/PostFinalStepForm ";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import VideoTrim from "./post/VideoTrim";
import createPost from "../assets/images/createPost.png";
import createPostBlack from "../assets/images/createPostBlack.png";

const CropImageMenu = ({ anchorEl, handleClose, handleCrop }) => {
  const handleMenuItemClick = (aspectRatio) => {
    handleCrop(aspectRatio);
    handleClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={() => handleMenuItemClick(1)}>1:1</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(4 / 5)}>4:5</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(16 / 9)}>16:9</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(3 / 2)}>Original</MenuItem>
    </Menu>
  );
};

function PostModal({ open, handleClose, handleSubmit }) {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark" ? true : false;
  const [caption, setCaption] = useState("");
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);

  const [fileType, setFileType] = useState("");
  // const [zoom, setZoom] = useState(1);
  const [zoomBarVisible, setZoomBarVisible] = useState(false);
  const fileInputRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [stage, setStage] = useState(1); // 1: Choose File, 2: Apply Filter, 3: Add Caption
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [aspectRatio, setAspectRatio] = useState("default"); // Default aspect ratio

  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect, setAspect] = useState(4 / 3);

  const openCropMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the aspect ratio menu
  const closeCropMenu = () => {
    setAnchorEl(null);
  };

  // Function to handle aspect ratio change
  const handleCropImage = (aspectRatio) => {
    if (aspectRatio === null) {
      setAspect(null); // Use null for original aspect ratio
    } else {
      setAspect(aspectRatio);
    }

    setAspectRatio(aspectRatio);

    console.log("Cropping image to aspect ratio:", aspectRatio);
  };

  // Compute aspect ratio dimensions
  const computeDimensions = (aspectRatio, originalDimensions) => {
    const containerWidth = 450; // Replace with actual container width
    const containerHeight = 300; // Replace with actual container height

    let width, height;

    switch (aspectRatio) {
      case "1:1":
        width = containerWidth;
        height = containerWidth;
        break;
      case "4:5":
        width = containerWidth;
        height = (containerWidth * 5) / 4;
        break;
      case "16:9":
        width = containerWidth;
        height = (containerWidth * 9) / 16;
        break;
      case "original":
        width = originalDimensions.width;
        height = originalDimensions.height;
        break;
      default:
        width = "100%";
        height = containerHeight;
    }

    return { width, height };
  };

  const originalDimensions = { width: "100%", height: "100%" };

  const { width, height } = computeDimensions(aspectRatio, originalDimensions);

  const handleCloseConfirmation = (action) => {
    if (action === "save") {
      saveDraft(); // Save as draft
    } else if (action === "discard") {
      discardChanges(); // Discard changes
    }
    setShowConfirmation(false);
  };

  const saveDraft = () => {
    // handleClose();
    const formData = new FormData();
    formData.append("file", file);

    console.log("formData :", formData);
  };

  const discardChanges = () => {
    setFile(null);
    setCaption("");
    setStage(1);
    handleClose();
  };

  const handleCloseModal = () => {
    if (file || caption) {
      setShowConfirmation(true);
    } else {
      handleClose();
    }
  };

  const handleNextStage = () => {
    if (stage < 3) setStage(stage + 1);
    handleNextClick1();
  };

  const handlePreviousStage = () => {
    if (stage > 1) setStage(stage - 1);
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length) {
      const newFile = files[0];
      setFileType(newFile.type.split("/")[0]); // 'image' or 'video'

      if (fileType === "video") {
        setFile(newFile);
      } else {
        setFile(URL.createObjectURL(newFile));
      }
    }
  }, []);

  const handleImageChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFile(URL.createObjectURL(newFile));
      setFileType(newFile.type.split("/")[0]); // 'image' or 'video'
    }
  };

  const handleFormSubmit = (e) => {
    setFile(null);
    setFile1(null);

    setCaption("");
    setStage(1);
  };

  const triggerFileInput = (e) => {
    // Reset the file input value
    e.target.value = null;
    fileInputRef.current?.click(); // Use the ref to trigger the file input
  };

  const handleZoomToggle = () => {
    setZoomBarVisible(!zoomBarVisible);
  };

  // const handleZoomChange = (e, newValue) => {
  //   setZoom(newValue);
  // };

  const handleClickOutside = (e) => {
    if (zoomBarVisible && !e.target.closest(".zoom-bar-container")) {
      setZoomBarVisible(false);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
  }, []);

  const handleZoomChange = (e, newValue) => {
    setZoom(newValue);
  };

  const [croppedArea, setCroppedArea] = useState(null);

  const handleCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleNextClick1 = async () => {
    try {
      if (fileType === "image" && croppedArea) {
        // Handle image cropping
        const croppedImageBlob = await getCroppedImg(file, croppedArea);
        const croppedImageURL = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(croppedImageBlob);
        });
        setFile1(croppedImageURL);
      } else if (fileType === "video") {
        if (file && file instanceof Blob) {
          const videoURL = URL.createObjectURL(file);
          setFile1(videoURL); // Store the video URL or Blob for use in the next stage
        } else {
          console.error("The file is not a valid Blob or File:", file);
        }
      } else {
        console.error("The fileType is not recognized:", fileType);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
    }
  };

  // const handleImageProcessed = (filteredImageURL) => {
  //   console.log("Filtered Image URL: ", filteredImageURL);
  //   // Now you can pass this URL to another component or save it as needed
  // };

  const filteredImg = useSelector((state) => state.filteredImage.imageSrc);

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ m: 2, border: "none" }}
        onClick={handleClickOutside} // Handle click outside to hide zoom bar
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? 300 : 500,
            // height: isSmallScreen ? 300 : 400,
            bgcolor: "background.paper",
            border: "none !important",
            borderRadius: 5,
            boxShadow: 24,
            pt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            minHeight: 500,
            overflow: "hidden",
          }}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()} // Allow dropping
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px",
              height: "15px",
            }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={handlePreviousStage}
              aria-label="close"
              sx={{
                position: "absolute",
                top: 10,
                left: 15,
                fontSize: "20px",
                display: stage > 1 ? "flex" : "none",
              }}
            >
              <Icon icon="eva:arrow-back-outline" />
            </IconButton>

            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2, mt: -2.5 }}
            >
              {stage === 2 ? "Edit" : "Create a Post"}
            </Typography>
            <IconButton
              disabled={file ? false : true}
              edge="end"
              color="blue"
              onClick={handleNextStage}
              aria-label="close"
              sx={{
                position: "absolute",
                top: 10,
                right: 20,
                fontSize: 16,
                color: "#4588f5",
                display: stage === 3 ? "none" : "flex",
              }}
            >
              {/* <CloseIcon /> */}
              Next
            </IconButton>
          </span>
          <Divider />

          {stage === 1 && (
            <Box
              sx={{
                width: "100%",
                height: 500,
                // border: "1px solid #ddd",
                // borderRadius: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
                background: "inherit",
                objectFit: "contain",
              }}
            >
              {file ? (
                fileType === "image" ? (
                  <>
                    {/* <img
                      src={file}
                      alt="Preview"
                      style={{
                        objectFit: "cover", // Maintain aspect ratio and cover container
                        transform: `translate(-50%, -50%) scale(${zoom})`,
                        transition:
                          "transform 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: width,
                        height: height,
                      }}
                    /> */}

                    <Cropper
                      image={file}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspect}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                      showGrid={false}
                      style={{
                        containerStyle: {
                          width: "100%", // Full width of the parent container
                          height: "100%", // Full height of the parent container
                          position: "relative", // Ensure it is positioned correctly within the parent
                        },
                        imageStyle: {
                          objectFit: "cover", // Ensures the image covers the container
                          width: "100%",
                          height: "100%", // Ensure the image fills the cropper
                        },
                      }}
                    />
                  </>
                ) : (
                  // <Cropper
                  //   video={file}
                  //   crop={crop}
                  //   zoom={zoom}
                  //   aspect={aspect}
                  //   onCropChange={setCrop}
                  //   onZoomChange={setZoom}
                  //   onCropComplete={handleCropComplete}
                  //   showGrid={false}
                  //   style={{
                  //     containerStyle: {
                  //       width: "100%", // Full width of the parent container
                  //       height: "100%", // Full height of the parent container
                  //       position: "relative", // Ensure it is positioned correctly within the parent
                  //     },
                  //     imageStyle: {
                  //       objectFit: "cover", // Ensures the image covers the container
                  //       width: "100%",
                  //       height: "100%", // Ensure the image fills the cropper
                  //     },
                  //   }}
                  // />

                  <video
                    src={file}
                    autoPlay
                    style={{ width: "100%", height: "100%" }}
                    onClick={(e) => {
                      const video = e.currentTarget;
                      if (video.paused) {
                        video.play();
                      } else {
                        video.pause();
                      }
                    }}
                  />
                )
              ) : (
                <label
                  htmlFor="file-upload"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    textAlign: "center",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    height: "100%",
                    padding: 2,
                    boxSizing: "border-box",
                  }}
                >
                  <div>
                    {/* <AddAPhotoOutlinedIcon
                      color="error"
                      sx={{ fontSize: 50, textAlign: "center" }}
                    /> */}
                    <img
                      src={darkMode ? createPost : createPostBlack}
                      height={75}
                      width={100}
                    />
                    <Typography sx={{ mt: 2 }}>
                      Drag and Drop your image or video
                    </Typography>
                    <Button
                      onClick={triggerFileInput} // Trigger file input
                      size="small"
                      variant="contained"
                      color="error"
                      sx={{
                        mt: 2,
                        fontSize: "13px",
                        fontWeight: "900",
                        textTransform: "none", // Disable uppercase text transformation
                        borderRadius: "6px", // Rounded corners
                        padding: "5px 20px", // Adjust padding for a more compact button
                        boxShadow: "none", // Remove default Material-UI shadow
                        "&:hover": {
                          backgroundColor: "#fe90d", // Darker red on hover
                          boxShadow: "none", // Keep shadow removed on hover
                        },
                      }}
                    >
                      Select from computer
                    </Button>
                  </div>
                  <input
                    type="file"
                    id="file-upload"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*,video/*"
                    onChange={handleImageChange}
                  />
                </label>
              )}

              {file && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 8, // Adjusted to position above the zoom icon
                    right: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  {zoomBarVisible && (
                    <Box
                      className="zoom-bar-container"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        borderRadius: 5,
                        mb: 1,
                        width: "80%",
                        pl: 2,
                        pr: 2,
                        mb: 4,
                      }}
                    >
                      <Slider
                        value={zoom}
                        size="small"
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={handleZoomChange}
                        aria-labelledby="zoom-slider"
                        sx={{ color: "#fff", width: 100 }}
                      />
                    </Box>
                  )}
                  {/* {fileType === "image" && (
                    <> */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 2, // Adjusted to position above the zoom icon
                      right: 8,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                      color: darkMode ? "inherit" : "#fff",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={handleZoomToggle}
                      sx={{
                        display: fileType === "video" ? "none" : "flex",
                        height: 30,
                        width: 30,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: darkMode ? "inherit" : "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      <ZoomInIcon />
                      {/* <Icon icon="lucide:zoom-in" height={30} width={30} /> */}
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 2, // Adjusted to position above the zoom icon
                      right: 50,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={openCropMenu}
                      sx={{
                        display: fileType === "video" ? "none" : "flex",

                        color: darkMode ? "inherit" : "#fff",
                        height: 30,
                        width: 30,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: darkMode ? "inherit" : "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      <CropFreeOutlinedIcon />
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 2, // Adjusted to position above the zoom icon
                      right: fileType === "video" ? 20 : 92,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={triggerFileInput} // Trigger file input
                      sx={{
                        color: darkMode ? "inherit" : "#fff",
                        height: 30,
                        width: 30,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: darkMode ? "inherit" : "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      {/* <Icon icon="tabler:replace" /> */}
                      <ChangeCircleOutlinedIcon />
                    </IconButton>
                  </Box>
                  {/* </>
                  )} */}
                  <CropImageMenu
                    anchorEl={anchorEl}
                    handleClose={closeCropMenu}
                    handleCrop={handleCropImage}
                  />
                </Box>
              )}
            </Box>
          )}

          {file && stage === 1 && (
            <>
              <Button
                size="small"
                variant="outlined"
                color="error"
                sx={{
                  display: fileType === "video" ? "none" : "none",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#ed544a",
                  },
                  mb: 1,
                  mt: 1,
                }}
                onClick={triggerFileInput} // Trigger file input
              >
                Change your file
              </Button>
              <input
                id="file-upload"
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*,video/*" // Accepts both images and videos
              />
            </>
          )}

          {stage === 2 &&
            (fileType === "image" ? (
              <PostFilers
                imageSrc={file1}
                // onImageProcessed={handleImageProcessed}
              />
            ) : (
              <PostFinalStepForm
                selectedImage={file}
                mediaType={fileType}
                handleFormSubmit={handleFormSubmit}
                handleCloseModal={handleClose}
              />
            ))}

          {stage === 3 && (
            <PostFinalStepForm
              handleCloseModal={handleClose}
              selectedImage={filteredImg}
              mediaType={fileType}
              handleFormSubmit={handleFormSubmit}
            />
          )}
        </Box>
      </Modal>

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            width: "90vw",
            maxWidth: "350px",
            padding: "0px 20px",
          },
          "& .MuiDialogTitle-root": {
            fontSize: "18px",
            fontWeight: 600,
            // color: "#262626",
          },
          "& .MuiDialogContent-root": {
            fontSize: "13px",
            color: "#8e8e8e",
          },
          "& .MuiDialogActions-root": {
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiButton-root": {
            fontSize: "13px",
            fontWeight: 500,
            padding: "10px",
            textTransform: "none",
            borderRadius: "8px",
            boxShadow: "none", // Remove box shadow
            "&:hover": {
              boxShadow: "none", // Prevent box shadow on hover
            },
          },
          "& .MuiButton-containedPrimary": {
            backgroundColor: "#f0f0f0",
          },
          "& .MuiButton-containedRed": {
            color: "#f44336",
            border: "1px solid #fff",
            "&:hover": {
              color: "#f44336", // Change text color to #f44336 on hover
              border: "1px solid #f44336",
            },
          },
          "& .MuiButton-outlinedRed": {
            border: "1px solid #f44336",
            "&:hover": {
              border: "1px solid #f44336",
            },
          },
        }}
      >
        <DialogTitle>Discard Post?</DialogTitle>
        <DialogContent>
          <Typography>If you leave, your edits won't be saved.</Typography>
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} width="100%">
            <Button
              onClick={() => handleCloseConfirmation("keepEditing")}
              variant="contained"
              className="MuiButton-containedRed"
              sx={{
                color: "#f44336",
                border: "1px solid transparent",

                "&:hover": {
                  border: "1px solid #f44336",
                  backgroundColor: "#f0f0f0 !important",
                },
              }}
            >
              Keep editing
            </Button>
            <Button
              onClick={() => handleCloseConfirmation("discard")}
              variant="contained"
              color="error"
            >
              Discard
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PostModal;

// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ThemeProvider,
  CssBaseline,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AppRouter from "./routes/AppRouter";
import createAppTheme from "./theme/AppTheme";
import { SnackbarProvider } from "./utils/snackbar/SnackbarService";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedPreference = localStorage.getItem("darkMode");
    return savedPreference ? JSON.parse(savedPreference) : false;
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  const currentTheme = createAppTheme(darkMode ? "dark" : "light");

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Router>
        <SnackbarProvider>
          <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
          <div style={{ display: "flex", marginTop: 35 }}>
            <Sidebar />
            <Container
              style={{
                marginLeft: isSmallScreen ? 0 : 200, //handled for mobile section
                transition: "margin-left 0.5s",
                padding: "20px 8px",
                flexGrow: 1,
                overflowX: "hidden",
              }}
            >
              <AppRouter />
            </Container>
          </div>
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;

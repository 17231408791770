import { db } from "./firebaseConfig"; // Your Firebase config
import { doc, updateDoc } from "firebase/firestore";

// Function to update user's isActive status
export const updateUserStatus = async (userId, status) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    await updateDoc(userRef, {
      isActive: status,
    });
    // console.log(`User ${userId} status updated to: ${status}`);
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

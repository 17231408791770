import { createSlice } from "@reduxjs/toolkit";

export const filteredImageSlice = createSlice({
  name: "filteredImage",
  initialState: {
    imageSrc: null,
  },
  reducers: {
    setFilteredImage: (state, action) => {
      state.imageSrc = action.payload;
    },
    clearFilteredImage: (state) => {
      state.imageSrc = null;
    },
  },
});

export const { setFilteredImage, clearFilteredImage } =
  filteredImageSlice.actions;

export default filteredImageSlice.reducer;

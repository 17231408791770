// src/theme/AppTheme.js
import { colors, createTheme } from "@mui/material";

const createAppTheme = (mode) =>
  createTheme({
    palette: {
      mode: mode, // 'light' or 'dark'
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: mode === "dark" ? "#18191B" : "#FEFEFE",
        paper: mode === "dark" ? "#18191B" : "#FEFEFE",
      },
    },
    typography: {
      fontSize: 13, // Set the base font size to 13px
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: "24px",
              height: "100%",
              "& fieldset": {
                borderColor: "#edece8",
              },
              "&:hover fieldset": {
                borderColor: "#edece8",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fa594d",
                border: "0.5px solid #fa594d",
                boxShadow: "0 0 1px 1px rgba(250, 89, 77, 0.5)", // Adding box-shadow here
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "6px 10px",
            },
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            "*::-webkit-scrollbar": {
              width: "12px",
            },
            "*::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
              border: "3px solid #f1f1f1",
            },
            "*::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
            body: {
              fontSize: "12px", // Ensure the global font size is applied to the body
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "transparent",
              color: "#De3744 ",
            },
            fontSize: 20, // Set the icon size to 18px
            padding: "0 10px",
            color: "#535454",
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            height: "18px",
            width: "15px",
            padding: 0,
            fontSize: 12, // Set the badge font size to 12px
          },
        },
      },
    },
  });

export default createAppTheme;

import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  useTheme,
  Badge, // Add Badge from MUI
} from "@mui/material";
import {
  collection,
  onSnapshot,
  query,
  where,
  addDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Cookies from "js-cookie";

const ChatUsers = ({ users, selectUser, currentUser }) => {
  const theme = useTheme();
  const authToken = Cookies.get("authToken");
  const [contact, setContact] = useState([]);

  useEffect(() => {
    const getUsers = () => {
      const chatCollection = collection(db, "Users");

      // Real-time listener for Users collection
      const unsubscribe = onSnapshot(
        chatCollection,
        (snapshot) => {
          const chatList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setContact(chatList);
        },
        (error) => {
          console.error("Error fetching chat data: ", error);
        }
      );

      // Cleanup listener on unmount
      return () => unsubscribe();
    };

    getUsers();
  }, []);

  const createNewChat = async (user) => {
    const currentUserId = currentUser;
    if (!currentUserId) {
      console.error("No user logged in");
      return;
    }

    const q = query(
      collection(db, "Chats"),
      where("participants", "array-contains", currentUserId)
    );

    const querySnapshot = await getDocs(q);
    let chatExists = false;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(user.id)) {
        chatExists = true;
        selectUser({ chatId: doc.id, ...user });
      }
    });

    if (!chatExists) {
      try {
        const newChat = await addDoc(collection(db, "Chats"), {
          participants: [currentUserId, user.id],
          createdAt: new Date(),
        });
        selectUser({ chatId: newChat.id, ...user });
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    }
  };

  return (
    <Paper
      sx={{
        padding: "8px",
        borderRadius: "8px",
        maxHeight: "100%",
        minHeight: "100%",
        overflowY: "auto",
      }}
      className="custom-scrollbar"
    >
      <List>
        {contact.map((user) => (
          <ListItem
            key={user.id}
            button
            onClick={() => createNewChat(user)}
            sx={{
              borderRadius: "8px",
              marginBottom: "8px",
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.1)"
                    : "rgba(0,0,0,0.05)",
              },
              backgroundColor: user.selected ? "#efefef" : "inherit",
            }}
          >
            <ListItemAvatar>
              {/* Badge component to show active/inactive status */}

              <Badge
                overlap="circular"
                badgeContent=""
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#52b202", // Apply lightGreen color
                    border: `2px solid white`, // Optional: white border around badge
                    width: "13px", // Set width of the badge
                    height: "13px", // Set height of the badge
                    minWidth: "13px", // Adjust the minimum width
                    minHeight: "13px", // Adjust the minimum height
                  },
                }}
                invisible={!user.isActive} // Only show if the user is active
              >
                <Avatar alt={user.name} src={`/avatars/${user.id}.png`} />
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={`${user.name}${user.id === currentUser ? " (You)" : ""}`}
              primaryTypographyProps={{ variant: "subtitle1" }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ChatUsers;

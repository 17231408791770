import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const StylishCircularProgress = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // Ensure the glow effect stays centered
      }}
    >
      <CircularProgress
        sx={{
          color: "transparent", // Base color transparent
          animationDuration: "1.5s", // Faster animation speed
          thickness: 2, // Slimmer progress indicator
          size: 30, // Custom size
          "& .MuiCircularProgress-circle": {
            strokeLinecap: "round", // Rounded stroke ends
            stroke: "url(#enhancedGradient)", // Enhanced gradient
            filter: "drop-shadow(0px 0px 15px rgba(255, 165, 0, 0.8))", // Stronger glow effect
          },
        }}
        size={30} // Custom size
      />
      <svg width="0" height="0">
        <defs>
          <linearGradient
            id="enhancedGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop
              offset="0%"
              style={{ stopColor: "#ff007f", stopOpacity: 1 }}
            />
            <stop
              offset="50%"
              style={{ stopColor: "#ff8f00", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#ff4500", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
      </svg>
      <Box
        sx={{
          position: "absolute",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          animation: "pulseGlow 3s infinite ease-in-out", // Pulsating glow animation
          zIndex: -1,
        }}
      />
      <style>
        {`
          @keyframes pulseGlow {
            0% {
              box-shadow: 0 0 15px rgba(255, 165, 0, 0.6), 0 0 30px rgba(255, 165, 0, 0.6);
            }
            50% {
              box-shadow: 0 0 30px rgba(255, 69, 0, 0.8), 0 0 60px rgba(255, 69, 0, 0.8);
            }
            100% {
              box-shadow: 0 0 15px rgba(255, 165, 0, 0.6), 0 0 30px rgba(255, 165, 0, 0.6);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default StylishCircularProgress;

// src/utils/snackbarUtils.js
import { useSnackbar } from "./SnackbarService";
export const useSnackbarUtils = () => {
  const showSnackbar = useSnackbar();

  const showSuccessSnackbar = (message) => {
    showSnackbar(message, "success");
  };

  const showErrorSnackbar = (message) => {
    showSnackbar(message, "error");
  };

  const showInfoSnackbar = (message) => {
    showSnackbar(message, "info");
  };

  const showWarningSnackbar = (message) => {
    showSnackbar(message, "warning");
  };

  return {
    showSuccessSnackbar,
    showErrorSnackbar,
    showInfoSnackbar,
    showWarningSnackbar,
  };
};

import React from "react";
import { SketchPicker } from "react-color"; // Ensure you have `react-color` installed

const ColorPicker = ({ onChange }) => {
  const handleChangeComplete = (color) => {
    onChange(color.hex);
  };

  return <SketchPicker color="#fff" onChangeComplete={handleChangeComplete} />;
};

export default ColorPicker;

// src/components/SnackbarService.js
import React, { useState, createContext, useContext } from "react";
import { Snackbar, Alert } from "@mui/material";

// Create a context for the Snackbar service
const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "success", // success, error, warning, info
  });

  const showSnackbar = (message, severity) => {
    setSnackbarData({
      open: true,
      message,
      severity,
    });
  };

  const handleClose = () => {
    setSnackbarData((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbarData.open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarData.severity}
          sx={{ width: "100%" }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Custom hook to use the Snackbar context
export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CardActions,
  Box,
  useMediaQuery,
  Skeleton,
  useTheme,
  TextField,
  Tooltip,
  InputAdornment,
  Avatar,
  CircularProgress,
  Button,
} from "@mui/material";
import { PlayCircleOutline } from "@mui/icons-material";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import CommentsModal from "./comments/CommentsModal"; // Import the updated CommentsModal component
import { Icon } from "@iconify/react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PostMoreMenuModal from "../more-menus/PostMoreMenuModal";
import ShareModal from "../share/ShareModal ";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPicker from "emoji-picker-react"; // Import the emoji picker
import SendIcon from "@mui/icons-material/Send";
import { getRequest, postRequest } from "../api-service";
import { useMutation } from "@tanstack/react-query";
import { handleFollowUser, handlePostLike } from "../http/postApiCalls";
import { queryClient } from "../http";
import { formatTimeAgo } from "../utils/FormatTimeAgo";
import { useNavigate } from "react-router-dom";

const activeVideoRefs = new Set(); // Global reference for active videos

function Post({ post, loading1, postFinalRefetch, postData, darkMode }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);
  // console.log("post", post.created_at);
  // console.log("postData", postData);

  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [loading, setLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(true); // Default to muted
  const [isPaused, setIsPaused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [likePost, setLikePost] = useState(post.liked_by_user);
  const [likeCount, setLikeCount] = useState(post.likes_count);
  const [commentCount, setCommentCount] = useState(51);
  const [isPosted, setIsPosted] = useState(false);
  const [postForShareModal, setPostForShareModal] = useState([false]);

  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const [selectedPost, setSelectedPost] = useState([]);
  const [comment, setComment] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleEmojiClick = (event, emojiObject) => {
    // Log the full event to see its structure

    // If emojiObject is undefined, try accessing the emoji through the event
    const emoji = emojiObject?.emoji || event.emoji || event.native;

    if (emoji) {
      setComment((prevComment) => prevComment + emoji);
    } else {
      console.error("Emoji not found in the event object", event);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenMoreMenu(true);
  };

  const handleCloseModal = () => {
    setOpenMoreMenu(false);
  };

  // const handleLikePost = (id) => {
  //   postRequest("/community-post/togglelike-post", { post_id: id }).then(
  //     (res) => {
  //       console.log("post liked");
  //     }
  //   );
  //   setLikePost(!likePost);

  //   setLikeCount(likePost ? likeCount - 1 : likeCount + 1);
  // };
  const handleLikePost = (id) => {
    // Optimistically update the UI
    setLikePost(!likePost);
    setLikeCount(likePost ? likeCount - 1 : likeCount + 1);

    // Send the request to the server
    // postRequest("/community-post/togglelike-post", { post_id: id })
    //   .then((res) => {
    //     console.log("post liked/disliked");
    //   })
    //   .catch((error) => {
    //     console.error("Error liking/disliking post", error);
    //     // Revert the UI update if the request fails
    //     setLikePost(likePost);
    //     setLikeCount(likePost ? likeCount + 1 : likeCount - 1);
    //   });

    const formData = new FormData();
    formData.append("post_id", id);

    postLike(formData);
  };

  const { mutate: postLike, reset: postLikeReset } = useMutation({
    mutationKey: ["post-like"],
    mutationFn: handlePostLike,
    onSuccess: (data) => {
      console.log("final data", data);
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        queryClient.invalidateQueries(["getAllPostData"]);
      } else {
        // showErrorToast(data?.message, darkMode);
      }

      postLikeReset();
    },
  });

  // const isImage =
  //   post?.media_details[0]?.media_urls?.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  // const isVideo =
  //   post?.media_details[0]?.media_urls?.match(/\.(mp4|webm|ogg)$/) !== null;

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    if (videoRef.current) {
      if (videoRef.current?.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
    setIsPaused(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (videoRef.current) {
      if (videoRef.current?.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
    setIsPaused(false);
  };
  const [sendModalOpen, setSendModalOpen] = useState(false);

  const handleModalSend = (post) => {
    setPostForShareModal(post);
    setSendModalOpen(true);
  };
  const handleCloseModalSend = () => {
    setSendModalOpen(false);
  };

  useEffect(() => {
    // Simulate loading for demonstration purposes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current?.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeVideoRefs.forEach((ref) => {
          if (ref !== videoRef.current && !ref?.paused) {
            ref.pause();
          }
        });
        activeVideoRefs.add(videoRef.current);
        if (videoRef.current?.paused && !isMuted) {
          videoRef.current.play().catch(console.error);
          setIsPaused(false);
        }
      } else {
        if (!videoRef.current?.paused) {
          videoRef?.current?.pause();
          setIsPaused(true);
        }
      }
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoRef.current]);

  const handlePostComment = (id) => {
    setIsPosted(true);
    const formData = new FormData();
    if (comment.trim()) {
      // Logic to post the comment
      formData.append("post_id", id);
      formData.append("comment", comment);
      postRequest(
        "/community-post/community-post-comments/add-comment",
        formData
      ).then((res) => {
        setIsPosted(false);
      });
      // Reset the comment value to an empty string
      setComment("");
    }
  };

  const userCheck = localStorage.getItem("loggedIn_user_id");

  const handleGoToOtherUserProfile = (id) => {
    navigate(userCheck === id.toString() ? "/profile" : `/other/profile/${id}`);
  };

  const handleDoFollowUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("type", "single");
    formData.append("status", 1);
    followUser(formData);
  };

  // Fetch users with a limit of 30 initially
  const { mutate: followUser, reset: followUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleFollowUser(fd),
    onSuccess: (data) => {
      setLoading(false);
      if (data?.status) {
        setLoading(false);
      }
      followUserReset();
    },
    onError: (data) => {
      setLoading(false);
    },
  });

  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        // marginBottom: "20px",
        padding: " 10px",
        minHeight: 350,
        border: "none",
        boxShadow: "none",
        maxWidth: isSmallScreen ? "100%" : "70%",
        m: isSmallScreen ? "0" : "auto",
      }}
      // onClick={handleClickOutside}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: -1,
          mb: 1,
          justifyContent: "space-between",
        }}
      >
        {loading ? (
          <div style={{ display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{
                bgcolor: "#bbbdbf",
                width: 40,
                height: 40,
                borderRadius: "50%",
              }}
              variant="circular"
              width={40}
              height={40}
            />
            <Skeleton
              animation="wave"
              sx={{ bgcolor: "#bbbdbf", ml: 1 }}
              variant="text"
              width={100}
              height={40}
            />
          </div>
        ) : (
          <>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
                cursor: "pointer",
              }}
              onClick={() => handleGoToOtherUserProfile(post.user_id)}
            >
              <Avatar
                src={`https://api.silocloud.io/get-file-content/${post?.profile_image_path}`}
                alt={post.username}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  textTransform: "capitalize",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "900 !important",
                  fontSize: "1rem",
                  ml: 1,
                  textTransform: "capitalize",
                }}
              >
                {post.username}{" "}
                {/* <Button
                  onClick={() => handleDoFollowUser(post.user_id)}
                  // className={
                  //   theme.palette.mode === "dark"
                  //     ? "profile-section-btn-follow"
                  //     : "profile-section-btn-follow"
                  // }
                  variant="outlined"
                  color="error"
                  sx={{
                    ml: 1,
                    border: "none",
                    "&:hover": {
                      border: "none",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : post.following_status ? (
                    "Following"
                  ) : (
                    "Follow"
                  )}
                </Button> */}
              </Typography>
            </span>
            <IconButton onClick={() => handleOpenModal(post)}>
              <MoreHorizIcon sx={{ cursor: "pointer", mr: -1 }} />
            </IconButton>
          </>
        )}
      </Box>
      {loading ? (
        <Skeleton
          animation="wave"
          sx={{ bgcolor: "#bbbdbf", borderRadius: 5, mt: 1 }}
          variant="rectangular"
          width={"100%"}
          height={375}
        />
      ) : (
        <>
          {post?.media_details[0]?.media_type === "image" && (
            <CardMedia
              component="img"
              image={post?.media_details[0]?.media_urls}
              alt={post.caption}
              // maxHeight={"300px"}
              width={"100%"}
              sx={{
                borderRadius: 1,
                mt: 2,
                maxHeight: "450px",
                objectFit: "cover",
              }}
            />
            // <CardMedia
            //   component="div"
            //   sx={{
            //     position: "relative",
            //     width: "100%", // Adjust as needed
            //     paddingTop: "90%", // Creates a square container (aspect ratio 1:1)
            //     borderRadius: 1,
            //     mt: 2,
            //     // backgroundColor: "#000",
            //     overflow: "hidden", // Ensures no overflow of the image
            //     textAlign: "center",
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //     m: "auto", // Center the component horizontally
            //     border: darkMode ? "1px solid #1c1c1c" : "1px solid #cdd1ce",
            //   }}
            // >
            //   <img
            //     src={post?.media_details[0]?.media_urls}
            //     alt={post.caption}
            //     style={{
            //       position: "absolute",
            //       top: "50%",
            //       left: "50%",
            //       transform: "translate(-50%, -50%)", // Center the image within the container
            //       maxWidth: "100%",
            //       maxHeight: "100%",
            //       objectFit: "cover", // Ensures the image fits within the container without distortion
            //     }}
            //   />
            // </CardMedia>
          )}
          {post?.media_details[0]?.media_type === "video" && (
            <CardMedia
              component="div"
              sx={{
                position: "relative",
                width: "100%", // Adjust as needed
                height: "0",
                paddingTop: "100%", // Aspect ratio 1:1 for square (change this for rectangular views)
                borderRadius: 1,
                mt: 2,
                backgroundColor: "#000",
                overflow: "hidden", // Ensures no overflow of video
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                objectFit: "contain",
                m: "auto",
                border: darkMode ? "1px solid #1c1c1c" : "1px solid #cdd1ce",
              }}
            >
              <video
                ref={videoRef}
                src={post?.media_details[0]?.media_urls}
                loop
                autoPlay
                playsInline
                muted={isMuted}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Ensures the video maintains its aspect ratio
                }}
                onClick={handlePlayPauseToggle}
              />
              {isPaused && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    borderRadius: "50%",
                    padding: 1,
                  }}
                >
                  <PlayCircleOutline
                    onClick={handlePlayPauseToggle} // Toggle play/pause on click
                    sx={{
                      fontSize: isSmallScreen ? 40 : 60,
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  bottom: isSmallScreen ? 2 : 10,
                  right: isSmallScreen ? 2 : 10,
                }}
              >
                <IconButton
                  onClick={handleMuteToggle}
                  sx={{ color: "white" }}
                  variant="text"
                >
                  {isMuted ? (
                    <VolumeOffOutlinedIcon />
                  ) : (
                    <VolumeUpOutlinedIcon />
                  )}
                </IconButton>
              </Box>
            </CardMedia>
          )}
        </>
      )}
      <CardContent sx={{ ml: -1, padding: "10px 0 0 16px" }}>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        > */}
        <CardActions sx={{ gap: 1, mt: -1, ml: -2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleLikePost(post.id)}
          >
            <Icon
              icon={likePost ? "ant-design:heart-filled" : "solar:heart-linear"}
              style={{
                color: likePost
                  ? "#ec1313"
                  : theme.palette.mode === "dark"
                  ? "#fff"
                  : "#000",
                fontSize: 25,
                width: "1.1em",
                height: "1em",
              }}
            />
            {/* <span style={{ fontSize: "15px" }}>{likeCount}</span> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleModalOpen}
          >
            <Icon icon="iconamoon:comment-light" style={{ fontSize: 25 }} />
            {/* <span style={{ fontSize: "15px" }}>{post.comment_count}</span> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleModalSend(post)}
          >
            <Icon
              icon="tabler:send"
              style={{ fontSize: 23, marginTop: "5%" }}
            />
            {/* <span style={{ fontSize: "15px" }}>{467}</span> */}
          </Box>
        </CardActions>
        {/* </Box> */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "900 !important", fontSize: 14 }}
        >
          {`${likeCount === 1 ? `${likeCount} Like` : `${likeCount} Likes`}`}
        </Typography>

        <Typography
          variant="body2"
          // color="textSecondary"
          sx={{ display: "flex" }}
          component="p"
          fontSize={14}
        >
          <span>
            <span className="bold-username">{post.username} </span>

            {isExpanded ? post.caption : `${post.caption?.substring(0, 50)}...`}
            {post.caption?.length > 50 && (
              <span
                onClick={toggleIsExpanded}
                style={{
                  cursor: "pointer",
                  color: "#bfbfbf",
                  fontSize: "13px",
                }}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </span>
            )}
          </span>
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          {formatTimeAgo(post?.created_at)}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Aligns emoji icon and TextField vertically
            mt: 1,
            position: "relative",
          }}
        >
          <Tooltip title="Add Emoji">
            <IconButton
              onClick={toggleEmojiPicker}
              edge="start"
              sx={{
                backgroundColor: "transparent",
                // mr: 0.5, // Add margin to the right for spacing
              }}
            >
              <EmojiEmotionsIcon sx={{ color: "#d1d1d1" }} fontSize="18px" />
            </IconButton>
          </Tooltip>
          <TextField
            // rows={2}
            maxRows={2}
            className="custom-scrollbar-chat"
            fullWidth
            multiline
            variant="standard"
            placeholder="Add a comment..."
            disabled={isPosted}
            value={comment}
            onChange={handleCommentChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents the default newline behavior
                handlePostComment(post.id); // Function to post the comment
              }
            }}
            sx={{
              backgroundColor: "transparent", // Instagram-like background
              "& .MuiInputBase-root": {
                backgroundColor: "transparent", // Instagram-like background
                paddingTop: "12px", // Adjust padding for multiline TextField
                paddingBottom: "12px",
                borderBottom: "1px solid #dbdbdb", // Instagram-like border

                // Set a fixed height
                height: "auto", // Allows the TextField to be flexible with content but limited by maxHeight
                maxHeight: "50px", // Adjust this value to set the maximum height
                resize: "none", // Disable resizing to maintain fixed height

                "&.Mui-focused": {
                  backgroundColor: "transparent",
                  "& .MuiInputBase-input": {
                    borderBottomColor: "#dbdbdb", // Ensure the border color remains consistent
                  },
                },

                "&:hover": {
                  backgroundColor: "transparent", // Remove background color on hover
                },
              },

              // Remove the default underline of the TextField
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
              "& .MuiInput-underline:hover:before": {
                display: "none",
              },
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <Tooltip title="Send">
          <IconButton
            disabled={!comment}
            onClick={handlePostComment}
            edge="end"
            sx={{
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent", // Ensures no hover effect
              },
            }}
          >
            <SendIcon
              sx={{ color: comment ? "#fa594d" : "#FCEBEC" }}
            />
          </IconButton>
        </Tooltip> */}
                </InputAdornment>
              ),
            }}
          />

          {isPosted ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20px",
                width: "20px",
              }}
            >
              <CircularProgress
                color="error"
                size={20} // This directly sets the diameter of the CircularProgress
                thickness={5} // Optional: Adjust the thickness of the spinner
              />
            </Box>
          ) : (
            <Tooltip title="Send">
              <IconButton
                disabled={!comment}
                onClick={() => handlePostComment(post.id)}
                edge="end"
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent", // Ensures no hover effect
                  },
                }}
              >
                <SendIcon sx={{ color: comment ? "#fa594d" : "#FCEBEC" }} />
              </IconButton>
            </Tooltip>
          )}
          {showEmojiPicker && (
            <Box
              ref={emojiPickerRef}
              sx={{
                position: "absolute",
                bottom: "60px", // Adjust as needed
                left: "0",
                zIndex: 1,
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </Box>
          )}
        </Box>
      </CardContent>

      {isModalOpen && (
        <CommentsModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          postData={post}
          imageUrl={post?.media_details[0]?.media_urls}
          caption={post.caption}
          postFinalRefetch={postFinalRefetch}
        />
      )}

      {sendModalOpen && (
        <ShareModal
          open={sendModalOpen}
          handleClose={handleCloseModalSend}
          post={post}
        />
      )}

      <PostMoreMenuModal
        open={openMoreMenu}
        onClose={handleCloseModal}
        post={selectedPost}
      />
    </Card>
  );
}

export default Post;

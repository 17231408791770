import React from "react";
import {
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Box,
  Container,
  Tooltip,
  Skeleton,
} from "@mui/material";
import ProfileSectionPosts from "./profile/ProfileSectionPosts";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import { handleGetUsersPosts } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";

function Profile({ username }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const user = {
    username: username,
    avatarUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
    bio: "Journey through the globe with me 🌟 | Discover hidden gems & unique experiences | Dream big, travel far ✨",
    followers: 1234,
    following: 567,
    postsCount: 89,
    posts: [
      {
        id: 1,
        username: "Tim Bravis",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "Immersed in the heart of nature, where every leaf and flower whispers a tale of tranquility and timeless beauty. A perfect retreat from the chaos, reminding us to pause and appreciate life simple wonders ❤️❤️💖☮️🕊️",
        postImg:
          "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      },
      {
        id: 2,
        username: "John Christian",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "In nature tranquil embrace, we discover a symphony of peace and beauty.💖 Each moment spent here is a gentle reminder to cherish the simplicity and serenity of the world around us.💗☮️🕊️💝",
        postImg:
          "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
      },
      {
        id: 3,
        username: "Tim Bravis",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "Immersed in the heart of nature, where every leaf and flower whispers a tale of tranquility and timeless beauty. A perfect retreat from the chaos, reminding us to pause and appreciate life simple wonders ❤️❤️💖☮️🕊️",
        postImg:
          "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      },
      {
        id: 4,
        username: "John Christian",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "In nature tranquil embrace, we discover a symphony of peace and beauty.💖 Each moment spent here is a gentle reminder to cherish the simplicity and serenity of the world around us.💗☮️🕊️💝",
        postImg:
          "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      },
      {
        id: 5,
        username: "Tim Bravis",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "Immersed in the heart of nature, where every leaf and flower whispers a tale of tranquility and timeless beauty. A perfect retreat from the chaos, reminding us to pause and appreciate life simple wonders ❤️❤️💖☮️🕊️",
        postImg:
          "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      },
      {
        id: 6,
        username: "John Christian",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgup07l-IhHq_3j2u1iEK9tJWGJGKInZWA&s",
        caption:
          "In nature tranquil embrace, we discover a symphony of peace and beauty.💖 Each moment spent here is a gentle reminder to cherish the simplicity and serenity of the world around us.💗☮️🕊️💝",
        postImg:
          "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
      },
      // Add more posts here
    ],
  };
  const fallbackUser = {
    username: <Skeleton component={Skeleton.Text} />,

    avatarUrl: "https://via.placeholder.com/150",
    bio: "No bio available.",
    followers: 0,
    following: 0,
    posts: [],
  };

  const {
    data: userPostsData,
    isLoading: userPostFinalIsLoading,
    isRefetching: userPostFinalIsRefetch,
    refetch: userPostFinalRefetch,
  } = useQuery({
    queryKey: ["getUserPosts"],
    queryFn: async () => handleGetUsersPosts(),
    gcTime: 0,
    staleTime: Infinity,
  });
  const userPosts = userPostsData || fallbackUser; // Fallback to avoid undefined errors

  // console.log("userPosts", userPosts);

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        padding: "0 !important",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderBottom="0.5px solid #ddd"
        mb={2}
        mt={3}
        pb={2}
        width="100%"
        px={2}
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isSmallScreen ? "column" : "row"}
          width="100%"
        >
          <Avatar
            // src={user.avatarUrl}
            src={`https://api.silocloud.io/get-file-content/${userPosts?.profile_image_path}`}
            alt={userPosts?.username}
            style={{
              width: 150,
              height: 150,
              marginRight: isSmallScreen ? "0px" : "20px",
              marginBottom: isSmallScreen ? "20px" : "0px",
            }}
          />
          <Box textAlign={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, textTransform: "capitalize" }}
            >
              {userPosts?.username}
            </Typography>

            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              mt={1}
              mb={1}
            >
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {userPosts?.posts?.length}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Posts
                </Typography>
              </Box>
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {userPosts.followers_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Followers
                </Typography>
              </Box>
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {userPosts.following_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Following
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                maxWidth: "500px",
              }}
            >
              {userPosts.about_me}
            </Typography>

            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              mt={2}
            >
              <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                variant="outlined"
                sx={{ mr: 1, color: "inherit" }}
              >
                Edit Profile
              </Button>
              <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                variant="outlined"
                sx={{ mr: 1, color: "inherit" }}
              >
                View Archive
              </Button>
              <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                sx={{ mr: 1, color: "inherit", border: "transparent" }}
              >
                <Icon icon="lets-icons:setting-alt-line" fontSize={30} />
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          width="100%"
          mt={4}
          mb={4}
        >
          <Tooltip title="Add Highlight" placement="top-end">
            <Button
              className={
                theme.palette.mode === "dark"
                  ? "profile-section-highlight-dark"
                  : "profile-section-highlight"
              }
              sx={{
                borderRadius: "50% !important",
                mr: 1,
                color: theme.palette.mode === "dark" ? "#696666" : "#b0b3b8",
                border: "transparent",
                height: 80,
                width: 80,
              }}
            >
              <Icon icon="mingcute:add-line" fontSize={50} />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <ProfileSectionPosts posts={userPosts?.posts} />
    </Container>
  );
}

export default Profile;

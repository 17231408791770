// src/pages/NotFoundPage.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import notFound from '../assets/images/404.png'

function NotFoundPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        textAlign: 'center',
      }}
    >
      <Typography sx={{fontWeight:'900'}} variant="h1" component="h1" >
        404
      </Typography>
      <Typography variant="h4" component="h2" sx={{mb:2}}>
        Page Not Found
      </Typography>
   
      <img src={notFound}/>
      <Button variant="contained" color="warning" component={Link} to="/" sx={{mt:2}}>
        Go to Home
      </Button>
    </Box>
  );
}

export default NotFoundPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filePaths: [],
};

const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFilePaths: (state, action) => {
      state.filePaths = action.payload;
    },
    addFilePaths: (state, action) => {
      const { file } = action.payload;
      state.filePaths.push(file);
    },
    removeFilePath: (state, action) => {
      state.filePaths = state.filePaths.filter(
        (path, index) => index !== action.payload
      );
    },
    clearFilePaths: (state) => {
      state.filePaths = [];
    },
  },
});

export const { setFilePaths, addFilePaths, removeFilePath, clearFilePaths } =
  fileSlice.actions;

export default fileSlice.reducer;

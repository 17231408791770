import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Box,
  Container,
  Tooltip,
  CircularProgress,
  Alert,
} from "@mui/material";
import ProfileSectionPosts from "./profile/ProfileSectionPosts";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTheme } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { handleGetOtherUserData } from "../http/getApiCalls";
import Loader from "../utils/progress/Loader";
import { handleFollowUser } from "../http/postApiCalls";

function OtherUserProfile() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { userID } = useParams();
  const [loading, setLoading] = useState(false);

  const handleDoFollowUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("type", "single");
    formData.append("status", user.following_status ? 2 : 1);
    followUser(formData);
  };

  const { mutate: followUser, reset: followUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleFollowUser(fd),
    onSuccess: (data) => {
      setLoading(false);
      if (data?.status) {
        setLoading(false);
        refetch();
      }
      followUserReset();
    },
    onError: (data) => {
      setLoading(false);
      refetch();
    },
  });

  // Fetch the user's data from API
  const {
    data: userPosts,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["getOtherUserPost", userID],
    queryFn: () => handleGetOtherUserData(userID),
    staleTime: Infinity,
  });

  // Fallback data when userPosts is unavailable
  const fallbackUser = {
    username: "Username",
    avatarUrl: "https://via.placeholder.com/150",
    bio: "No bio available.",
    followers: 0,
    following: 0,
    posts: [],
  };

  const user = userPosts?.[0] || fallbackUser; // Fallback to avoid undefined errors

  // Render loading state
  if (isLoading) {
    return (
      <Container
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "center", mt: 4 }}
      >
        <Loader />
        {/* <CircularProgress /> */}
      </Container>
    );
  }

  // Render error state
  if (isError) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">
          Failed to load user profile. <Button onClick={refetch}>Retry</Button>
        </Alert>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0 !important",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderBottom="0.5px solid #ddd"
        mb={2}
        mt={3}
        pb={8}
        width="100%"
        px={2}
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isSmallScreen ? "column" : "row"}
          width="100%"
        >
          <Avatar
            src={`https://api.silocloud.io/get-file-content/${
              user.profile_image_path || user.avatarUrl
            }`}
            alt={user.username}
            style={{
              width: 150,
              height: 150,
              marginRight: isSmallScreen ? "0px" : "20px",
              marginBottom: isSmallScreen ? "20px" : "0px",
            }}
          />
          <Box textAlign={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, textTransform: "capitalize" }}
            >
              {user.username}
              <Button
                onClick={() => handleDoFollowUser(user.user_id)}
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-follow"
                    : "profile-section-btn-follow"
                }
                variant="outlined"
                sx={{ mr: 1, ml: 2, p: 0.5 }}
              >
                {loading ? (
                  <CircularProgress size={18} color="inherit" />
                ) : user.following_status ? (
                  "Unfollow"
                ) : (
                  "Follow"
                )}
              </Button>
            </Typography>

            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              mt={1}
              mb={1}
            >
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {user.posts?.length || 0}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Posts
                </Typography>
              </Box>
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {user.followers_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Followers
                </Typography>
              </Box>
              <Box mx={1} textAlign="center">
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {user.following_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Following
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                maxWidth: "500px",
              }}
            >
              {user.about_me}
            </Typography>

            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              mt={2}
            >
              {/* <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                variant="outlined"
                sx={{ mr: 1, color: "inherit" }}
              >
                Follow
              </Button> */}
              {/* <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                variant="outlined"
                sx={{ mr: 1, color: "inherit" }}
              >
                View Archive
              </Button>
              <Button
                className={
                  theme.palette.mode === "dark"
                    ? "profile-section-btn-dark"
                    : "profile-section-btn"
                }
                sx={{ mr: 1, color: "inherit", border: "transparent" }}
              >
                <Icon icon="lets-icons:setting-alt-line" fontSize={30} />
              </Button> */}
            </Box>
          </Box>
        </Box>

        {/* <Box
          display="flex"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          width="100%"
          mt={4}
          mb={4}
        >
          <Tooltip title="Add Highlight" placement="top-end">
            <Button
              className={
                theme.palette.mode === "dark"
                  ? "profile-section-highlight-dark"
                  : "profile-section-highlight"
              }
              sx={{
                borderRadius: "50% !important",
                mr: 1,
                color: theme.palette.mode === "dark" ? "#696666" : "#b0b3b8",
                border: "transparent",
                height: 80,
                width: 80,
              }}
            >
              <Icon icon="mingcute:add-line" fontSize={50} />
            </Button>
          </Tooltip>
        </Box> */}
      </Box>

      <ProfileSectionPosts posts={user.posts} />
    </Container>
  );
}

export default OtherUserProfile;

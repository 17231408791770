import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Avatar,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  useTheme,
  Typography,
  Divider,
  Box,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Icon } from "@iconify/react/dist/iconify.js";
import { handleGetSiloUsers } from "../http/postApiCalls";
import { useMutation } from "@tanstack/react-query";

const shareOptions = [
  {
    name: "Your Story",
    icon: <Icon icon="hugeicons:user-story" fontSize={25} />,
  },
  { name: "WhatsApp", icon: <WhatsAppIcon style={{ color: "#25D366" }} /> },
  { name: "Facebook", icon: <FacebookIcon style={{ color: "#4267B2" }} /> },
  { name: "Twitter", icon: <Icon icon="arcticons:x-twitter" fontSize={25} /> },
];

const ShareModal = ({ open, handleClose, post }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSharePost = () => {
    console.log("selected users", selectedUsers);
  };

  // Fetch users with a limit of 30 initially
  const { mutate: getUsers, reset: getUsersReset } = useMutation({
    mutationKey: ["get-users"],
    mutationFn: (fd) => handleGetSiloUsers(fd),
    onSuccess: (data) => {
      setLoading(false);
      if (data?.status) {
        setUsers(data.message.users);
      }
      getUsersReset();
    },
  });

  useEffect(() => {
    const fd = new FormData();
    fd.append("limit", 30); // Fetch initial 30 users
    getUsers(fd);
  }, []);

  const handleToggle = (user) => {
    const isSelected = selectedUsers.some((u) => u.id === user.id);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      // Trigger search
      setLoading(true);
      const fd = new FormData();
      fd.append("limit", 30); // Optional: Adjust this limit as needed
      fd.append("search", query); // Send search query to the backend
      getUsers(fd);
    } else {
      // Fetch the initial 30 users again if search query is cleared
      const fd = new FormData();
      fd.append("limit", 30);
      getUsers(fd);
    }
  };

  const shareUrl = `https://community.silocloud.io${post.shareable_link}`;

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      default:
        return;
    }
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 5 },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        <Typography
          variant="h6"
          sx={{ position: "absolute", right: "45%", top: 4, fontSize: "18px" }}
        >
          Share
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 5, color: "grey.500" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: 0,
          maxHeight: "350px",
        }}
      >
        {/* Selected Users Display */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "0 16px",
            paddingBottom: "8px",
            overflowX0: "auto",
          }}
        >
          {selectedUsers.map((user) => (
            <Box
              key={user.id}
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "4px",
                padding: "2px 8px",
                backgroundColor: darkMode ? "#444" : "#f0f1f2",
                borderRadius: "16px",
              }}
            >
              <Avatar
                alt={user.username}
                src={user.profile_image_path}
                sx={{ width: 24, height: 24, marginRight: 1 }}
              />
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                {user.username}
              </Typography>
            </Box>
          ))}
        </Box>
        <Divider />

        {/* Search Section */}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0 16px",
          }}
        >
          <Typography variant="h6" fontSize={14}>
            To:
          </Typography>
          <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search users"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            sx={{
              padding: "2px 16px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0px !important",
                "& fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  border: "0.5px solid transparent",
                  boxShadow: "none",
                },
              },
            }}
          />
        </span>
        <Divider />
        {/* Users List */}
        <List sx={{ padding: 0 }}>
          {loading
            ? Array.from(new Array(5))?.map((_, index) => (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ m: 1 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={100}
                    height={30}
                  />
                </span>
              ))
            : users.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleToggle(user)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px 16px",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: darkMode ? "#3d3d3d" : "#f0f1f2",
                    },
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={user.username}
                      src={user.profile_image_path}
                      sx={{ width: 40, height: 40, marginRight: 2 }}
                    />
                    <span style={{ fontWeight: 500 }}>{user.username}</span>
                  </div>
                  <div
                    style={{
                      height: "24px",
                      width: "24px",
                      border: "1px solid #De3744",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectedUsers.some((u) => u.id === user.id) && (
                      <CheckCircleIcon
                        sx={{
                          color: "#De3744",
                          fontSize: 22,
                        }}
                      />
                    )}
                  </div>
                </ListItem>
              ))}
        </List>
        <Divider />
      </DialogContent>
      <DialogActions sx={{ padding: "8px 16px", display: "block" }}>
        <Box sx={{ display: "flex", overflowX: "auto", padding: "8px 16px" }}>
          {shareOptions.map((option, index) => (
            <Box
              key={index}
              onClick={() => handleShare(option.name.toLowerCase())}
              sx={{
                flexShrink: 0,
                width: "80px",
                textAlign: "center",
                marginRight: "10px",
                padding: "8px",
                borderRadius: "12px",
                boxShadow: darkMode
                  ? "0px 4px 8px rgba(255, 255, 255, 0.1)"
                  : "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: darkMode ? "#3d3d3d" : "#fff",
                transition: "transform 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {option.icon}
              <Typography fontSize={12} sx={{ marginTop: "8px" }}>
                {option.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Button
          onClick={handleSharePost}
          variant="contained"
          size="large"
          fullWidth
          sx={{
            background: "#DE3744",
            color: "white",
            "&:hover": {
              backgroundColor: "#c4333d",
            },
          }}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;

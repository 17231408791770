import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Box,
  IconButton,
  LinearProgress,
  useMediaQuery,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  Close as CloseIcon,
  VolumeOff as MuteIcon,
  VolumeUp as UnmuteIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
} from "@mui/icons-material";
import StylishCircularProgress from "../../utils/progress/StylishCircularProgress ";
import Cancel from "@mui/icons-material/Cancel";

const buttonStyles = {
  position: "absolute",
  top: "50%",
  color: "white",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  borderRadius: "50%",
  padding: 10,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Subtle shadow
  transition: "background-color 0.3s ease, transform 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker on hover
    transform: "scale(1.1)", // Slightly larger on hover
  },
  "&:disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Lighter background when disabled
    boxShadow: "none", // No shadow when disabled
  },
};

const StoryModal = ({ open, handleClose, storiesData, currentIndex }) => {
  const [index, setIndex] = useState(currentIndex);
  const [storyIndex, setStoryIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const [videoState, setVideoState] = useState({
    currentTime: 0,
    isPlaying: false,
  });
  const videoRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const progressInterval = useRef(null);
  const progressDuration = 6000; // 6 seconds

  const currentStory = storiesData[index]?.stories[storyIndex];

  // Reset story index when modal opens
  useEffect(() => {
    if (open) {
      setIndex(currentIndex);
      setStoryIndex(0);
    }
  }, [open, currentIndex]);

  // Handle media end
  const handleMediaEnded = () => {
    if (storyIndex < storiesData[index]?.stories.length - 1) {
      setStoryIndex((prev) => prev + 1);
    } else if (index < storiesData.length - 1) {
      setIndex((prev) => prev + 1);
      setStoryIndex(0);
    } else {
      handleClose();
    }
  };

  // Handle progress bar for image stories
  useEffect(() => {
    if (currentStory?.media_type === "image") {
      const startTime = Date.now();
      setProgress(0);

      progressInterval.current = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newProgress = (elapsed / progressDuration) * 100;
        setProgress(newProgress);

        if (elapsed >= progressDuration) {
          clearInterval(progressInterval.current);
          handleMediaEnded();
        }
      }, 100);

      return () => {
        clearInterval(progressInterval.current);
      };
    }
  }, [currentStory, storyIndex]);

  // Handle video playback
  useEffect(() => {
    const videoElement = videoRef.current;

    if (currentStory?.media_type === "video" && videoElement) {
      videoElement.src = currentStory.mediaUrls;
      videoElement.muted = muted;
      videoElement.load();

      // Restore video state after changing mute/unmute
      videoElement.currentTime = videoState.currentTime;
      if (videoState.isPlaying) {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
      }

      const handleCanPlay = () => {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
        videoElement.removeEventListener("canplay", handleCanPlay);
      };

      videoElement.addEventListener("canplay", handleCanPlay);

      return () => {
        videoElement.removeEventListener("canplay", handleCanPlay);
        videoElement.pause();
      };
    }
  }, [currentStory, muted, videoState]);

  // Update progress bar for video stories
  const handleTimeUpdate = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement && currentStory?.media_type === "video") {
      const currentProgress =
        (videoElement.currentTime / videoElement.duration) * 100;
      setProgress(currentProgress);
    }
  }, [currentStory]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [handleTimeUpdate]);

  // Handle mute/unmute
  const toggleMute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setVideoState({
        currentTime: videoElement.currentTime,
        isPlaying: !videoElement.paused,
      });
      setMuted((prevMuted) => {
        videoElement.muted = !prevMuted;
        if (!videoElement.paused) {
          videoElement
            .play()
            .catch((error) => console.error("Video play error:", error));
        }
        return !prevMuted;
      });
    }
  };

  // Handle play/pause
  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isPlaying) {
        videoElement.pause();
      } else {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Handle navigation
  const handleNavigation = (direction) => {
    if (direction === "next") {
      if (storyIndex < storiesData[index]?.stories.length - 1) {
        setStoryIndex((prev) => prev + 1);
      } else if (index < storiesData.length - 1) {
        setIndex((prev) => prev + 1);
        setStoryIndex(0);
      }
    } else if (direction === "prev") {
      if (storyIndex > 0) {
        setStoryIndex((prev) => prev - 1);
      } else if (index > 0) {
        setIndex((prev) => prev - 1);
        setStoryIndex(storiesData[index - 1]?.stories.length - 1);
      }
    }
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        handleNavigation("next");
      } else if (e.key === "ArrowLeft") {
        handleNavigation("prev");
      } else if (e.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Set loading to true every time the story changes
  }, [currentStory]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleVideoLoad = () => {
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="story-modal-title"
      aria-describedby="story-modal-description"
      onClick={handleClose}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Tooltip title={"Close"}>
          <IconButton
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              zIndex: 1000,
            }}
          >
            <CloseIcon sx={{ height: 30, width: 30, color: "white" }} />
          </IconButton>
        </Tooltip>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isSmallScreen ? "100vw" : "100vw"}
          height="90vh"
          padding={"0 35px"}
          borderRadius={5}
          position="relative"
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            position="relative"
            height="100%"
            bgcolor="black"
            borderRadius={3}
            width={isSmallScreen ? "100vw" : "22vw"}
            // overflow="hidden"
          >
            {loading && (
              <Box
                position="absolute"
                top="50%"
                left="48%"
                transform="translate(-50%, -50%)"
                zIndex={10}
              >
                {/* <CircularProgress color="primary" /> */}
                <StylishCircularProgress />
              </Box>
            )}
            <span style={{ overflow: "hidden" }}>
              {currentStory?.media_type === "video" ? (
                <video
                  src={currentStory?.mediaUrls}
                  autoPlay
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: loading ? "none" : "block",
                  }}
                  onCanPlay={handleVideoLoad}
                  onEnded={handleMediaEnded}
                  // poster={currentStory?.mediaUrls} // Optional: add a poster for better UX
                />
              ) : (
                <img
                  src={currentStory?.mediaUrls}
                  alt="story"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: loading ? "none" : "block",
                  }}
                  onLoad={handleImageLoad}
                />
              )}
            </span>

            <Box
              position="absolute"
              top={-2}
              left={0}
              right={0}
              display="flex"
              justifyContent="space-between"
              p={1}
            >
              {storiesData[index]?.stories.map((story, i) => (
                <LinearProgress
                  key={i}
                  color="error"
                  variant="determinate"
                  value={storyIndex === i ? progress : i < storyIndex ? 100 : 0}
                  style={{ flexGrow: 1, margin: "0 2px", height: "2px" }}
                />
              ))}
            </Box>

            <Box
              position="absolute"
              top={10}
              left={10}
              display="flex"
              alignItems="center"
              color="white"
            >
              <Avatar
                src={`https://api.silocloud.io/get-file-content/${storiesData[index]?.profile_image_path}`}
                alt={storiesData[index]?.username}
                style={{
                  width: 40,
                  textTransform: "capitalize",
                  height: 40,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              />
              {/* <img
                src={`https://api.silocloud.io/get-file-content/${storiesData[index]?.profile_image_path}`}
                alt="avatar"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              /> */}
              <span style={{ textTransform: "capitalize" }}>
                {storiesData[index]?.username}
              </span>
            </Box>

            {currentStory?.media_type === "video" && (
              <>
                <IconButton
                  onClick={toggleMute}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 50,
                    color: "white",
                  }}
                >
                  {muted ? <MuteIcon /> : <UnmuteIcon />}
                </IconButton>
                <IconButton
                  onClick={togglePlayPause}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 20,
                    color: "white",
                  }}
                  aria-label={isPlaying ? "Pause video" : "Play video"}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </IconButton>
              </>
            )}

            <IconButton
              onClick={() => handleNavigation("prev")}
              style={{ ...buttonStyles, left: -40 }}
              disabled={index === 0 && storyIndex === 0}
            >
              <ArrowBackIcon />
            </IconButton>

            <IconButton
              onClick={() => handleNavigation("next")}
              style={{ ...buttonStyles, right: -40 }}
              disabled={
                index === storiesData.length - 1 &&
                storyIndex === storiesData[index]?.stories.length - 1
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StoryModal;

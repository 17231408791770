import React, { useRef, useState, useEffect } from "react";
import {
  TextField,
  Button,
  Slider,
  Tooltip,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ColorPicker from "../../utils/ColorPicker";
import FontFamilyPicker from "../../utils/FontFamilyPicker ";
import { handleAddStory, handleDraftStory } from "../../http/postApiCalls";
import { queryClient } from "../../http";
import { useMutation } from "@tanstack/react-query";
import { Icon } from "@iconify/react/dist/iconify.js";
import upload from "../../assets/images/createPost.png";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";

const CustomCropper = ({ handleFileSelect, handleCloseModal, handleClose }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [file, setFile] = useState(null);
  const [zoom, setZoom] = useState(1); // Default zoom level
  const [textBoxes, setTextBoxes] = useState([]);
  const [currentDragging, setCurrentDragging] = useState(null);
  const [draggingImage, setDraggingImage] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagePos, setImagePos] = useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState(null);
  const [mediaType, setMediaType] = useState("image");
  const [textColor, setTextColor] = useState("#ffffff");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [selectedTextBoxId, setSelectedTextBoxId] = useState(null);
  const [textStyles, setTextStyles] = useState(
    textBoxes.reduce((acc, tb) => {
      acc[tb.id] = {
        color: "#000000", // Default color
        fontFamily: "Arial", // Default font family
        fontSize: "24px", // Default font size
        bold: false, // Default bold style
      };
      return acc;
    }, {})
  );

  const canvasRef = useRef(null);
  const mediaRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (file && canvasRef.current) {
      const mediaElement =
        mediaType === "image" ? new Image() : document.createElement("video");
      mediaElement.src = URL.createObjectURL(file);

      mediaElement.onloadeddata = () => {
        mediaRef.current = mediaElement;
        drawCanvas();

        if (mediaType === "video") {
          mediaElement.play();
          const intervalId = setInterval(drawCanvas, 30);
          return () => clearInterval(intervalId);
        }
      };

      mediaElement.onload = () => {
        if (mediaType === "image") {
          mediaRef.current = mediaElement;
          drawCanvas();
        }
      };
    }
  }, [file, zoom, textBoxes, mediaType, imagePos, canvasRef]);

  const drawCanvas = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const canvasWidth = Math.max(canvas.width, 300); // Ensure canvas width is at least 300px
    const canvasHeight = canvas.height;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    if (mediaRef.current) {
      const mediaWidth = mediaRef.current.videoWidth || mediaRef.current.width;
      const mediaHeight =
        mediaRef.current.videoHeight || mediaRef.current.height;

      // Calculate aspect ratios
      const mediaAspectRatio = mediaWidth / mediaHeight;

      let drawWidth, drawHeight, offsetX, offsetY;

      // Scale media to fit canvas with a minimum width of 300px
      if (mediaAspectRatio >= 1) {
        // Media is landscape or square
        drawWidth = Math.max(canvasWidth * zoom, 300);
        drawHeight = drawWidth / mediaAspectRatio;
        offsetX = (canvasWidth - drawWidth) / 2;
        offsetY = (canvasHeight - drawHeight) / 2;
      } else {
        // Media is portrait
        drawHeight = canvasHeight * zoom;
        drawWidth = Math.max(drawHeight * mediaAspectRatio, 300);
        offsetX = (canvasWidth - drawWidth) / 2;
        offsetY = (canvasHeight - drawHeight) / 2;
      }

      // Ensure that the image doesn't go out of the canvas bounds
      const cropX = Math.max(0, -offsetX);
      const cropY = Math.max(0, -offsetY);
      const cropWidth = Math.min(drawWidth, canvasWidth);
      const cropHeight = Math.min(drawHeight, canvasHeight);

      ctx.drawImage(
        mediaRef.current,
        (cropX / drawWidth) * mediaWidth,
        (cropY / drawHeight) * mediaHeight,
        (cropWidth / drawWidth) * mediaWidth,
        (cropHeight / drawHeight) * mediaHeight,
        offsetX,
        offsetY,
        drawWidth,
        drawHeight
      );

      // Draw text boxes on the media
      textBoxes.forEach(({ id, text, x, y }) => {
        const { color, fontFamily, fontSize, bold } = textStyles[id] || {};

        ctx.fillStyle = color || textColor;
        ctx.font = `${bold ? "bold" : "normal"} ${fontSize || "24px"} ${
          fontFamily || "Arial"
        }`;
        ctx.textAlign = "center";

        // Handle multi-line text
        const lineHeight = parseInt(fontSize, 10) * 1.2;
        const lines = text.split("\n");
        let textY = y;

        lines.forEach((line) => {
          ctx.fillText(line, x, textY);
          textY += lineHeight;
        });
      });
    }
  };

  const handleAddYourStory = async () => {
    setLoading(true);

    console.log("Upload story is Clicked");
    const formData = new FormData();
    formData.append("media_type", mediaType);
    formData.append("visibility", 0);

    if (mediaType === "image") {
      // For images, create a Blob from the canvas
      const canvas = canvasRef.current;

      canvas.toBlob((blob) => {
        // Append the Blob directly to FormData
        formData.append("media", blob, "story-view.png");

        // Upload formData after appending the media
        addStory(formData);
      }, "image/png");
    } else if (mediaType === "video") {
      // For videos, use the file directly
      // Append the file directly to FormData
      formData.append("media", file, "story-view.mp4");

      // Upload formData after appending the media
      addStory(formData);
    }
  };

  const { mutate: addStory, reset: addStoryReset } = useMutation({
    mutationKey: ["add-story"],
    mutationFn: handleAddStory,
    onSuccess: (data) => {
      console.log("Story added", data);
      setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries(["getStoryData"]);
        showSuccessSnackbar(data.message);
        // showSuccessToast(data?.message, darkMode);
        handleClose();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
      }
      addStoryReset();
    },
  });

  const handleSaveDraft = async () => {
    setLoading(true);

    console.log("Draft Story clicked");
    const formData = new FormData();
    formData.append("media_type", mediaType);
    formData.append("visibility", 0);
    formData.append("status", "draft");

    if (mediaType === "image") {
      // For images, create a Blob from the canvas
      const canvas = canvasRef.current;

      canvas.toBlob((blob) => {
        // Append the Blob directly to FormData
        formData.append("media", blob, "story-view.png");

        // Upload formData after appending the media
        draftStory(formData);
      }, "image/png");
    } else if (mediaType === "video") {
      // For videos, use the file directly
      // Append the file directly to FormData
      formData.append("media", file, "story-view.mp4");

      // Upload formData after appending the media
      draftStory(formData);
    }
  };

  const { mutate: draftStory, reset: draftStoryReset } = useMutation({
    mutationKey: ["draft-story"],
    mutationFn: handleDraftStory,
    onSuccess: (data) => {
      console.log("Story drafted", data);
      setLoading(false);
      queryClient.invalidateQueries(["getStoryData"]);

      if (data?.status) {
        queryClient.invalidateQueries(["getStoryData"]);

        showSuccessSnackbar(data.message);
        handleClose();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
      }
      draftStoryReset();
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      handleFileSelect();
      setFile(selectedFile);
      setMediaType(selectedFile.type.startsWith("image/") ? "image" : "video");
    }
  };

  const handleZoomChange = (event, newValue) => {
    // Map slider value to zoom level
    // Slider value ranges from -100 to 100
    // Zoom level ranges from 0.1 to 3 (you can adjust as needed)
    const newZoom = Math.max(0.1, 1 + newValue / 50);
    setZoom(newZoom);
  };

  const addTextBox = () => {
    const canvas = canvasRef.current;
    setTextBoxes([
      ...textBoxes,
      {
        id: Date.now(),
        text: "",
        x: canvas?.width / 2,
        y: canvas?.height / 2,
      },
    ]);
  };

  const updateTextBox = (id, updatedProps) => {
    setTextBoxes((prevTextBoxes) =>
      prevTextBoxes.map((box) =>
        box.id === id ? { ...box, ...updatedProps } : box
      )
    );
  };

  const removeTextBox = (id) => {
    setTextBoxes((prevTextBoxes) =>
      prevTextBoxes.filter((box) => box.id !== id)
    );
  };

  const handleTextChange = (id, e) => {
    updateTextBox(id, { text: e.target.value });
  };

  const startDragText = (id, e) => {
    e.preventDefault();
    setCurrentDragging(id);
  };

  const endDragText = () => {
    setCurrentDragging(null);
  };

  const handleDrag = (e) => {
    if (currentDragging !== null) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      updateTextBox(currentDragging, { x, y });
    }

    if (draggingImage) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const dx = x - dragStart.x;
      const dy = y - dragStart.y;

      setImagePos((prevPos) => {
        const newX = Math.max(
          0,
          Math.min(
            prevPos.x + dx,
            mediaRef.current.width / zoom - containerRef.current.offsetWidth
          )
        );
        const newY = Math.max(
          0,
          Math.min(
            prevPos.y + dy,
            mediaRef.current.height / zoom - containerRef.current.offsetHeight
          )
        );

        return { x: newX, y: newY };
      });

      setDragStart({ x, y });
    }
  };

  const startImageDrag = (e) => {
    e.preventDefault();
    setDraggingImage(true);
    const rect = containerRef.current.getBoundingClientRect();
    setDragStart({
      x: e.clientX - rect.left - imagePos.x,
      y: e.clientY - rect.top - imagePos.y,
    });
  };

  const endImageDrag = () => {
    setDraggingImage(false);
  };

  //   const handleColorChange = (color) => {
  //     setTextStyles((prev) => ({
  //       ...prev,
  //       [selectedTextBoxId]: { ...prev[selectedTextBoxId], color },
  //     }));
  //   };

  //   const handleFontFamilyChange = (fontFamily) => {
  //     setTextStyles((prev) => ({
  //       ...prev,
  //       [selectedTextBoxId]: { ...prev[selectedTextBoxId], fontFamily },
  //     }));
  //   };

  const [anchorElColor, setAnchorElColor] = useState(null);
  const [anchorElFont, setAnchorElFont] = useState(null);
  //   const [textStyles, setTextStyles] = useState({});

  //   const handleTextEditClick = (id) => {
  //     setSelectedTextBoxId(id);
  //   };

  const handleTextEditClick = (id) => {
    setSelectedTextBoxId(id);
  };

  const handleColorClick = (event, id) => {
    setSelectedTextBoxId(id);
    setAnchorElColor(event.currentTarget);
  };

  const handleFontClick = (event, id) => {
    setSelectedTextBoxId(id);
    setAnchorElFont(event.currentTarget);
  };

  const handleCloseColorPicker = () => {
    setAnchorElColor(null);
  };

  const handleCloseFontPicker = () => {
    setAnchorElFont(null);
  };

  //   const handleColorChange = (color) => {
  //     setTextBoxes((prev) =>
  //       prev.map((tb) =>
  //         tb.id === selectedTextBoxId ? { ...tb, color: color } : tb
  //       )
  //     );
  //     handleCloseColorPicker();
  //   };

  //   const handleFontFamilyChange = (fontFamily) => {
  //     setTextBoxes((prev) =>
  //       prev.map((tb) =>
  //         tb.id === selectedTextBoxId ? { ...tb, fontFamily: fontFamily } : tb
  //       )
  //     );
  //     handleCloseFontPicker();
  //   };

  const handleColorChange = (color) => {
    setTextStyles((prev) => {
      const updatedStyles = {
        ...prev,
        [selectedTextBoxId]: {
          ...prev[selectedTextBoxId],
          color: color,
        },
      };
      console.log("Updated Text Styles:", updatedStyles); // Check the updated styles
      return updatedStyles;
    });
    handleCloseColorPicker();
  };

  const handleFontFamilyChange = (fontFamily) => {
    setTextStyles((prev) => {
      const updatedStyles = {
        ...prev,
        [selectedTextBoxId]: {
          ...prev[selectedTextBoxId],
          fontFamily: fontFamily,
        },
      };
      console.log("Updated Text Styles:", updatedStyles); // Check the updated styles
      return updatedStyles;
    });
    handleCloseFontPicker();
  };

  const handleCloseConfirmation = (action) => {
    if (action === "save") {
      saveDraft(); // Save as draft
    } else if (action === "discard") {
      discardChanges(); // Discard changes
    }
    setShowConfirmation(false);
  };

  const discardChanges = () => {
    setFile(null);
  };
  const saveDraft = () => {
    const formData = new FormData();
    formData.append("visibility", 0);

    console.log(formData);
  };

  return (
    <div style={{ display: "flex", width: "100%", height: "600px" }}>
      <Box
        sx={{
          width: 320,
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", // Adjusted to center content vertically
          // backgroundColor: "#ffffff",
          borderRadius: 2,
          // boxShadow: 1,
          gap: 2, // Added gap for spacing between children
        }}
      >
        <Typography variant="h6" sx={{ color: "#1976d2", marginBottom: 2 }}>
          Choose File
        </Typography>

        <IconButton
          component="label"
          sx={{
            color: "#ffffff",
            background: "linear-gradient(45deg, #f44336, #d32f2f)",
            borderRadius: "50%",
            padding: 3,
            // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            transition:
              "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            "&:hover": {
              background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
              transform: "scale(1.05)",
              // boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
              color: "#fff",
            },
          }}
        >
          <input
            type="file"
            accept="image/*,video/*"
            hidden
            onChange={handleFileChange}
          />
          {/* <UploadFileIcon fontSize="large" sx={{ fontSize: "2.5rem" }} /> */}
          <img src={upload} height={40} width={43} />
        </IconButton>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          // color="secondary"
          onClick={addTextBox}
          sx={{
            mt: 3, // Added margin-top to separate from IconButton
            borderRadius: 3,
            border: "1px solid #f44336",

            textTransform: "none",
            width: "100%",
            padding: "5px 0",
            fontSize: "1rem",
            display: mediaType === "video" ? "none" : "flex",
            color: "#De3744",
            "&:hover": {
              backgroundColor: "#c62828",
              color: "#fff",
              border: "1px solid #f44336",
            },
          }}
        >
          Add Text
        </Button>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
            mt: "auto", // Ensures the buttons stay at the bottom of the Box
          }}
        >
          <Button
            variant="contained"
            // color="secondary"
            startIcon={<Icon icon="hugeicons:user-story" />}
            onClick={() => handleAddYourStory()}
            sx={{
              backgroundColor: "#f44336",
              borderRadius: 3,
              textTransform: "none",
              flex: 1, // Ensures button takes up available space
              padding: "5px 0",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#c62828",
                color: "#fff",
              },
            }}
            disabled={file ? false : true}
          >
            Post Story
          </Button>

          <Button
            variant="outlined"
            // color="secondary"
            startIcon={<Icon icon="hugeicons:license-draft" />} // Add an appropriate icon for save draft
            onClick={() => handleSaveDraft()}
            disabled={file ? false : true}
            sx={{
              border: "1px solid #f44336",
              color: "#f44336",
              borderRadius: 3,
              textTransform: "none",
              flex: 1, // Ensures button takes up available space
              padding: "5px 0",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#f44336",

                color: "#fff",
                border: "1px solid #f44336",
              },
            }}
          >
            Save Draft
          </Button>
        </Box>
      </Box>

      <div
        ref={containerRef}
        style={{ position: "relative", flex: 1 }}
        onMouseMove={handleDrag}
        onMouseUp={endDragText}
        onMouseLeave={endDragText}
      >
        {file && (
          <>
            <div
              style={{
                position: "relative",
                width: "300px",
                height: "550px",
                border: "1px solid #000",
                borderRadius: "10px",
                overflow: "hidden",
                margin: "auto",
                alignItems: "center",
                cursor: draggingImage ? "grabbing" : "grab",
              }}
              onMouseDown={startImageDrag}
              onMouseUp={endImageDrag}
            >
              <canvas
                ref={canvasRef}
                width={300}
                height={500}
                style={{ width: "100%", height: "100%" }}
              ></canvas>

              {textBoxes.map(({ id, text, x, y, color, fontFamily }) => (
                <div
                  key={id}
                  style={{
                    position: "absolute",
                    top: y,
                    left: x,
                    transform: "translate(-50%, -50%)",
                    pointerEvents: "auto",
                    zIndex: 1,
                    cursor: "grab",
                  }}
                  onMouseDown={(e) => startDragText(id, e)}
                >
                  <TextField
                    value={text}
                    minRows={2}
                    onChange={(e) => handleTextChange(id, e)}
                    placeholder="Type your text here..."
                    multiline
                    variant="standard"
                    sx={{
                      backgroundColor: "transparent",
                      border: `1px dashed rgba(${
                        color || "255, 255, 255"
                      }, 0.8)`,
                      borderRadius: 2,
                      width: "200px",
                      fontSize: "1rem",
                      color: color || "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none",
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 16,
                        color: color || "#fff",
                        padding: "5px",
                      },
                    }}
                    style={{ fontFamily: fontFamily || "Arial" }}
                  />
                  <Tooltip title="Edit Text Color">
                    <IconButton
                      onClick={(e) => handleColorClick(e, id)}
                      sx={{
                        position: "absolute",
                        top: "28px",
                        right: "10px",
                        minWidth: "auto",
                        padding: "2px",
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <ColorLensIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Font Family">
                    <IconButton
                      onClick={(e) => handleFontClick(e, id)}
                      sx={{
                        position: "absolute",
                        top: "2px",
                        right: "10px",
                        minWidth: "auto",
                        padding: "2px",
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <FontDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Button
                    onClick={() => removeTextBox(id)}
                    sx={{
                      position: "absolute",
                      top: "-8px",
                      right: "-10px",
                      minWidth: "auto",
                      padding: "3px",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: "rgba(255, 0, 0, 0.8)",
                      },
                    }}
                  >
                    <CancelIcon sx={{ fontSize: "1rem" }} />
                  </Button>
                  <Popover
                    open={Boolean(anchorElColor) && selectedTextBoxId === id}
                    anchorEl={anchorElColor}
                    onClose={handleCloseColorPicker}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ColorPicker onChange={handleColorChange} />
                  </Popover>
                  <Popover
                    open={Boolean(anchorElFont) && selectedTextBoxId === id}
                    anchorEl={anchorElFont}
                    onClose={handleCloseFontPicker}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <FontFamilyPicker onChange={handleFontFamilyChange} />
                  </Popover>
                </div>
              ))}
            </div>
            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Slider
                value={(zoom - 1) * 50} // Convert zoom to slider value
                min={0}
                max={100}
                step={1}
                onChange={handleZoomChange}
                aria-labelledby="zoom-slider"
                sx={{
                  marginTop: "10px",
                  display: mediaType === "video" ? "none" : "flex",
                }}
              />
            </div>
          </>
        )}
      </div>

      <Backdrop
        open={loading}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      >
        <CircularProgress color="error" />
      </Backdrop>
    </div>
  );
};

export default CustomCropper;

import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Feed from "../components/Feed";
import Stories from "../components/Stories";
import Chat from "../components/chats/Chat";

function Home() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Mobile screens
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablet/Medium screens
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Large screens

  return (
    <Grid
      container
      spacing={isSmallScreen ? 0 : 2}
      sx={{
        padding: isSmallScreen ? "0 8px" : isMediumScreen ? "0 24px" : "0 32px",
      }}
    >
      {/* Main Content Area (Stories + Feed) */}
      <Grid
        item
        xs={12}
        md={isLargeScreen ? 9 : 10}
        sx={{
          padding: isSmallScreen ? "0 8px" : "0 16px",
          marginBottom: isSmallScreen ? "16px" : 0,
        }}
      >
        <Stories />
        <Feed />
      </Grid>

      {/* Chat Column (Hidden on Small Screens) */}
      {!isSmallScreen && (
        <Grid
          item
          xs={12}
          md={isLargeScreen ? 3 : 2}
          sx={{
            textAlign: isMediumScreen ? "end" : "end",
            marginLeft: "auto",
            padding: isMediumScreen ? "0 16px" : "0 24px",
          }}
        >
          <Chat />
        </Grid>
      )}
    </Grid>
  );
}

export default Home;

import React, { useState } from "react";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  List,
  ListItem,
  useTheme,
} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
const notifications = [
  {
    id: 1,
    user: "Sophia Wilson",
    message: "liked your post",
    avatarUrl: "url1",
  },
  {
    id: 2,
    user: "Emily Davis",
    message: "commented on your post",
    avatarUrl: "url2",
  },
  // Add more notifications here
];

function NotificationsDropdown() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllAsRead = () => {
    console.log("Mark all as read");
  };

  const handleViewAll = () => {
    console.log("View all notifications");
  };

  return (
    <div>
      <IconButton
        aria-label="notifications"
        aria-controls="notification-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge
          badgeContent={notifications.length}
          color="error"
          sx={{ height: "fit-content", width: "fit-content" }}
        >
          <NotificationsNoneOutlinedIcon fontSize="20px" />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "0 3px 12px 1px #82252626",

            width: "300px",
            borderTop: "3px solid #e14954",
          },
        }}
      >
        <div
          style={{
            padding: "4px 16px",
            height: "fit-content",
            borderBottom: "1px solid #ccc",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor:
              theme.palette.mode === "dark" ? "#545453" : "#f5f6fa ",
          }}
        >
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography
            variant="body2"
            color="inherit"
            sx={{ cursor: "pointer", mt: 0.5 }}
            onClick={handleMarkAllAsRead}
          >
            Mark All as Read
          </Typography>
        </div>
        <List>
          {notifications.length === 0 ? (
            <MenuItem>
              <Typography
                variant="h6"
                align="center"
                fontSize={15}
                sx={{ m: "auto" }}
                color={"error"}
                fontWeight={"700 !important"}
              >
                No new notifications
              </Typography>
            </MenuItem>
          ) : (
            notifications.map((notification) => (
              <MenuItem key={notification.id} onClick={handleClose}>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar src={notification.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.user}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {notification.message}
                      </Typography>
                    }
                  />
                </ListItem>
              </MenuItem>
            ))
          )}
        </List>
        {notifications.length > 10 && (
          <div style={{ padding: "8px 16px", textAlign: "center" }}>
            <Typography
              variant="body2"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={handleViewAll}
            >
              View All
            </Typography>
          </div>
        )}
      </Menu>
    </div>
  );
}

export default NotificationsDropdown;

// Utility function to get time difference
export const formatTimeAgo = (timestamp) => {
  const now = new Date();
  const postDate = new Date(timestamp);
  const diffInMs = now - postDate;

  const diffInSecs = Math.floor(diffInMs / 1000);
  const diffInMins = Math.floor(diffInSecs / 60);
  const diffInHours = Math.floor(diffInMins / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInYears = now.getFullYear() - postDate.getFullYear();

  if (diffInYears > 1) {
    return postDate.toISOString().split("T")[0]; // Return the date in yyyy-mm-dd format
  }

  if (diffInDays > 7) {
    return `${diffInWeeks} weeks ago`;
  } else if (diffInDays >= 1) {
    return `${diffInDays} days ago`;
  } else if (diffInHours >= 1) {
    return `${diffInHours} hours ago`;
  } else if (diffInMins >= 1) {
    return `${diffInMins} minutes ago`;
  } else {
    return `${diffInSecs} seconds ago`;
  }
};

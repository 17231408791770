import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import ReportModal from "./ReportModal";
import UnfollowModal from "./UnfollowModal";
import ShareToModal from "./ShareToModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
import EmbedModal from "./EmbedModal";
import { useNavigate } from "react-router-dom";
import ShareModal from "../share/ShareModal ";
import { handleFollowUser } from "../http/postApiCalls";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../http";

const slideUp = keyframes`
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

const PostMoreMenuModal = ({ open, onClose, post }) => {
  const [openModal, setOpenModal] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOpenModal = (modalType) => {
    if (modalType === "copyLink") {
      handleCopyLink(); // Invoke the function to copy the link
    } else if (modalType === "goToPost") {
      handleGoToPost(); // Invoke the function to copy the link
    } else if (modalType === "share") {
      handleModalSend(); // Invoke the function to copy the link
    } else if (modalType === "unfollow") {
      handleDoFollowUser(post.user_id);
    }
    {
      setOpenModal(modalType);
    }
    onClose();
  };

  const handleDoFollowUser = (id) => {
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("type", "single");
    formData.append("status", post.following_status ? 2 : 1);
    followUser(formData);
  };

  const { mutate: followUser, reset: followUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleFollowUser(fd),
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries(["getAllPostData"]);
      }
      followUserReset();
    },
    onError: (data) => {},
  });

  const handleCloseModal = () => {
    setOpenModal(null);
  };
  const handleGoToPost = () => {
    const postUrl = post.shareable_link;

    navigate(postUrl, { replace: true });
  };

  const handleCopyLink = () => {
    const postUrl = `https://community.silocloud.io${post.shareable_link}`; // Replace with the actual post URL
    navigator.clipboard.writeText(postUrl);
    // navigate(post.shareable_link);
    // toast.success("Link copied to your clipboard!", {
    //   position: "bottom-right",
    //   autoClose: 2000,
    //   closeOnClick: true,
    //   pauseOnHover: false,
    //   draggable: true,
    //   progress: undefined,
    // });
  };

  const [sendModalOpen, setSendModalOpen] = useState(false);

  const handleModalSend = (post) => {
    // setPostForShareModal(post);
    setSendModalOpen(true);
  };
  const handleCloseModalSend = () => {
    setSendModalOpen(false);
  };

  const userCheck = localStorage.getItem("loggedIn_user_id");

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 350,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 0,
            animation: `${slideUp} 0.3s ease-out`,
          }}
        >
          <List sx={{ alignItems: "center" }}>
            <ListItem button onClick={() => handleOpenModal("report")}>
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    Report
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => handleOpenModal("unfollow")}
              disabled={userCheck === post.user_id?.toString()}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    {post.following_status ? "Unfollow" : "Follow"}
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("favourites")}>
              <ListItemText
                primary="Add to favourites"
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("goToPost")}>
              <ListItemText primary="Go to post" sx={{ textAlign: "center" }} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("share")}>
              <ListItemText
                primary="Share to..."
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("copyLink")}>
              <ListItemText primary="Copy Link" sx={{ textAlign: "center" }} />
            </ListItem>
            {/* <Divider />
            <ListItem button onClick={() => handleOpenModal("embed")}>
              <ListItemText primary="Embed" sx={{ textAlign: "center" }} />
            </ListItem> */}
            <Divider />
            <ListItem button onClick={() => handleOpenModal("about")}>
              <ListItemText
                primary="About this account"
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={onClose}>
              <ListItemText primary="Cancel" sx={{ textAlign: "center" }} />
            </ListItem>
          </List>
        </Box>
      </Modal>
      {/* Conditionally render each modal */}
      {openModal === "report" && (
        <ReportModal open={true} onClose={handleCloseModal} post={post} />
      )}
      {/* {openModal === "unfollow" && (
        <UnfollowModal open={true} onClose={handleCloseModal} user={post} />
      )} */}

      {openModal === "share" && (
        // <ShareToModal
        //   open={true}
        //   onClose={handleCloseModal}
        //   postUrl={`https://community.silocloud.io${post.shareable_link}`}
        // />
        <ShareModal
          open={sendModalOpen}
          handleClose={handleCloseModalSend}
          post={post}
        />
      )}

      {openModal === "embed" && (
        <EmbedModal open={true} onClose={handleCloseModal} post={post} />
      )}
      <ToastContainer />
    </>
  );
};

export default PostMoreMenuModal;

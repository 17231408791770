// import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: process.env.PUBLIC_FIREBASE_API_KEY,
//   authDomain: process.env.PUBLIC_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.PUBLIC_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.PUBLIC_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.PUBLIC_FIREBASE_APP_ID,
// };

// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBuYhyc9kUY6YqyuCvg825Rvbk29Au8WGc",
  authDomain: "chat-app-31985.firebaseapp.com",
  projectId: "chat-app-31985",
  storageBucket: "chat-app-31985.appspot.com",
  messagingSenderId: "902012263055",
  appId: "1:902012263055:web:f1ae200e97a798b8dbaefa",
  measurementId: "G-VVEED8FQD8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

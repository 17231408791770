import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  TextField,
  Grid,
  useMediaQuery,
  Typography,
  InputAdornment,
  Tooltip,
  Skeleton,
  CircularProgress,
  useTheme,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { PlayCircleOutline } from "@mui/icons-material";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import { Icon } from "@iconify/react/dist/iconify.js";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { getRequest, postRequest } from "../../api-service";
import { queryClient } from "../../http";
import { handlePostLike } from "../../http/postApiCalls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleGetPosts } from "../../http/getApiCalls";
import CommentInput from "../../utils/CommentInput";
import ReplyIcon from "@mui/icons-material/Reply";

function CommentsModal({
  isModalOpen,
  handleModalClose,
  imageUrl,
  caption,
  postData,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  const [likePost, setLikePost] = useState(postData.liked_by_user);
  const [likeCount, setLikeCount] = useState(postData.likes_count);

  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);
  // console.log("postData---", postData);
  const isImage = imageUrl?.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  const isVideo = imageUrl?.match(/\.(mp4|webm|ogg)$/) !== null;

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData", postData.id],
    queryFn: async () => handleGetPosts(postData.id),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isModalOpen) {
      postFinalRefetch(); // Refetch the data when the modal is opened
    }
  }, [isModalOpen, postFinalRefetch, isPosted]);
  console.log("postsData commentsmodal", postsData ? postsData[0] : postsData);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: 2,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: isSmallScreen ? 2.5 : 4,
    width: "90vw",
    minHeight: "80vh",
    maxHeight: "95vh",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const getCommentsOfPost = () => {
    // setLoading(true);

    getRequest(
      `/community-post/community-post-comments/get-comments?post_id=${postData.id}`
    )
      .then((res) => {
        setLoading(false);
        setComments(res.data.comments);
        console.log("comments res", res.data.comments);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });

    // postRequest("/community-post/community-post-comments/get-comments", {
    //   post_id: postData.id,
    // })
    //   .then((res) => {
    //     setLoading(false);
    //     setComments(res.data.comments);
    //     console.log("comments res", res.data.comments);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.log("error", err);
    //   });
  };
  const [replyingTo, setReplyingTo] = useState(null);
  const [commentReplyTrue, setCommentReplyTrue] = useState(false);
  const [replyingToName, setReplyingToName] = useState("");
  const [commentReplyId, setCommentReplyId] = useState(null);

  const handleReplyClick = (commentId, commentUsername) => {
    setCommentReplyId(commentId);
    setCommentReplyTrue(true);
    setReplyingToName(`@${commentUsername}`);
    setReplyingTo((prev) => (prev === commentId ? null : commentId));
  };

  const [viewingReplies, setViewingReplies] = useState(null);

  const handleViewRepliesClick = (commentId) => {
    setViewingReplies((prev) => (prev === commentId ? null : commentId));
  };
  const handlePostSuccess = () => {
    if (isModalOpen === true) {
      getCommentsOfPost();
      setReplyingTo(null);
      setReplyingToName("");
      setCommentReplyTrue(false);
      setCommentReplyId(null);
    }
  };

  useEffect(() => {
    if (isModalOpen === true) {
      getCommentsOfPost();
    }
  }, [isModalOpen, isPosted]);

  // api for like post
  const handleLikePost = (id) => {
    setLikePost(!likePost);
    setLikeCount(likePost ? likeCount - 1 : likeCount + 1);
    // postRequest("/community-post/togglelike-post", { post_id: id }).then(
    //   (res) => {
    //     console.log("post liked");
    //   }
    // );
    const formData = new FormData();
    formData.append("post_id", id);

    postLike(formData);
  };

  const { mutate: postLike, reset: postLikeReset } = useMutation({
    mutationKey: ["post-like"],
    mutationFn: handlePostLike,
    onSuccess: (data) => {
      // console.log("final data", data);
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        queryClient.invalidateQueries(["getAllPostData"]);
      } else {
        // showErrorToast(data?.message, darkMode);
      }

      postLikeReset();
    },
  });
  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{ position: "absolute", top: isSmallScreen ? 0 : 8, right: 15 }}
        >
          <IconButton edge="end" color="inherit" onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex", // Flexbox for alignment
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {postData?.media_details[0]?.media_type === "image" && (
                <img
                  src={imageUrl}
                  alt="Post"
                  style={{
                    maxWidth: "100%", // Ensure it doesn't overflow the grid
                    maxHeight: "500px", // Limit the height
                    borderRadius: 5,
                    // objectFit: "contain", // Maintain aspect ratio
                  }}
                />
              )}

              {postData?.media_details[0]?.media_type === "video" && (
                <>
                  <video
                    ref={videoRef}
                    src={imageUrl}
                    loop
                    autoPlay
                    playsInline
                    style={{
                      maxWidth: "100%", // Ensure it doesn't overflow the grid
                      maxHeight: "500px", // Limit the height
                      borderRadius: 5,
                      objectFit: "contain", // Maintain aspect ratio
                    }}
                    onClick={handlePlayPauseToggle}
                  />
                  {isPaused && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        borderRadius: "50%",
                        padding: 1,
                      }}
                    >
                      <PlayCircleOutline
                        onClick={handlePlayPauseToggle}
                        sx={{
                          fontSize: isSmallScreen ? 40 : 60,
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
            <div style={{ marginTop: "10px", gap: 15, display: "flex" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Icon
                  onClick={() => handleLikePost(postData.id)}
                  icon={"solar:heart-linear"}
                  style={{
                    color: "inherit",
                    fontSize: 25,
                    width: "1.1em",
                    height: "1em",
                  }}
                />
                <span style={{ fontSize: "15px" }}>{postData.likes_count}</span>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleLikePost(postData.id)}
              >
                <Icon
                  icon={
                    likePost ? "ant-design:heart-filled" : "solar:heart-linear"
                  }
                  style={{
                    color: likePost
                      ? "#ec1313"
                      : theme.palette.mode === "dark"
                      ? "#fff"
                      : "#000",
                    fontSize: 25,
                    width: "1.1em",
                    height: "1em",
                  }}
                />
                <span style={{ fontSize: "15px" }}>{likeCount}</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Icon icon="iconamoon:comment-light" style={{ fontSize: 25 }} />
                <span style={{ fontSize: "15px" }}>
                  {postsData
                    ? postsData[0].comment_count
                    : postData.comment_count}
                </span>
              </Box>
            </div>

            <Typography sx={{ display: isSmallScreen ? "none" : "block" }}>
              {caption}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "100%",
            }}
          >
            <List
              className="custom-scrollbar"
              sx={{
                overflowY: "auto",
                flexGrow: 1,
                maxHeight: isSmallScreen ? "40vh" : "70vh",
              }}
            >
              {loading
                ? Array.from(new Array(10))?.map((_, index) => (
                    <ListItem key={index}>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={30}
                        height={30}
                        sx={{ mr: 1, mt: -2 }}
                      />
                      <ListItemText
                        primary={
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="150px"
                          />
                        }
                        secondary={
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="100px"
                          />
                        }
                      />
                    </ListItem>
                  ))
                : comments?.map((comment) => (
                    <React.Fragment key={comment.id}>
                      <ListItem sx={{ alignItems: "flex-start" }}>
                        <Avatar
                          src={`https://api.silocloud.io/get-file-content/${comment?.profile_image_path}`}
                          alt={comment?.username}
                          sx={{
                            mr: 1,
                            height: 25,
                            width: 25,
                            textTransform: "capitalize",
                          }}
                        />
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  mr: 1,
                                  mt: -0.5,
                                  textTransform: "capitalize",
                                }}
                              >
                                {comment.username}
                              </Typography>
                            </Box>
                          }
                          secondary={
                            <Box sx={{ display: "", alignItems: "center" }}>
                              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                                {comment.comment}
                              </Typography>
                              <span style={{ display: "flex" }}>
                                <Typography
                                  variant="body"
                                  onClick={() =>
                                    handleReplyClick(
                                      comment.id,
                                      comment.username
                                    )
                                  }
                                  sx={{ padding: "5px", cursor: "pointer" }}
                                >
                                  {/* <ReplyIcon sx={{ fontSize: 20 }} /> */}
                                  Reply
                                </Typography>

                                {comment.reply_on_comment?.length > 0 && (
                                  <Typography
                                    variant="body"
                                    onClick={() =>
                                      handleViewRepliesClick(comment.id)
                                    }
                                    sx={{
                                      padding: "5px",
                                      cursor: "pointer",
                                      mt: 0.1,
                                    }}
                                  >
                                    {viewingReplies === comment.id
                                      ? "Hide "
                                      : `—View Replies (${comment.reply_on_comment.length})`}
                                  </Typography>
                                )}
                              </span>
                            </Box>
                          }
                        />
                      </ListItem>

                      {/* {replyingTo === comment.id && (
                        <Box sx={{ ml: 4 }}>
                          <CommentInput
                            replyToComment={true}
                            postId={comment.id}
                            onPostSuccess={handlePostSuccess}
                          />
                        </Box>
                      )} */}
                      {/* Replies List */}
                      <Collapse
                        in={viewingReplies === comment.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ ml: 5 }}>
                          {comment.reply_on_comment?.map((reply) => (
                            <ListItem
                              key={reply.id}
                              sx={{ alignItems: "flex-start" }}
                            >
                              <Avatar
                                src={`https://api.silocloud.io/get-file-content/${reply?.profile_image_path}`}
                                alt={reply?.profile_image_path}
                                sx={{ mr: 1, mt: 0.7, height: 20, width: 20 }}
                              />
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    {reply.username}
                                  </Typography>
                                }
                                secondary={reply.reply}
                              />
                            </ListItem>
                          ))}
                        </Box>
                      </Collapse>
                    </React.Fragment>
                  ))}
            </List>

            <CommentInput
              replyToComment={commentReplyTrue}
              postId={commentReplyTrue ? commentReplyId : postData.id}
              onPostSuccess={handlePostSuccess}
              commentReplyUserName={replyingToName}
            />
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default CommentsModal;

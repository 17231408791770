import React from "react";
import { MenuItem, Select } from "@mui/material";

const FontFamilyPicker = ({ onChange }) => {
  const fontFamilies = [
    "Arial",
    "Courier New",
    "Georgia",
    "Times New Roman",
    "Verdana",
  ];

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Select defaultValue="Arial" onChange={handleChange}>
      {fontFamilies.map((font) => (
        <MenuItem key={font} value={font}>
          {font}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FontFamilyPicker;
